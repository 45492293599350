import styled from "styled-components";
import { RSRow, Separator, TableRow, TableSubtitleRow } from "./ExtendedThermalCalculation";
import { getFloorScreedTranslations, getBearingFloorTranslations } from "../translations";
import { useThermalState } from "./ThermalStateProvider";
import { useTranslation } from "react-i18next";
import { SyntheticEvent, useMemo } from "react";
import { getEnumValue, parseMaterial } from "../../../../services/helpers.service";
import { FloorBearingTypes, FloorInsulationTypes } from "@iko-design-center/shared/lib/constants/constants";
import { useExtendedThermalCalculation } from "./extendedCalculation.hook";

const TableBody = styled.div`
`;

export const PreliminaryTableBody = () => {
    const { t } = useTranslation() as any;
    const { state, setState, performance } = useThermalState();
    const { formatCalc } = useExtendedThermalCalculation();

    const bearingFloorOptions = useMemo(() => {
        return [
            { value: state.bearingFloor.material.id, label: getBearingFloorTranslations(t, state.bearingFloor.type) },
            { value: "REINFORCED_CONCRETE_FLOOR", label: getBearingFloorTranslations(t, FloorBearingTypes.REINFORCED_CONCRETE_FLOOR) },
            { value: "RIBBED_CASSETTE_FLOOR", label: getBearingFloorTranslations(t, FloorBearingTypes.RIBBED_CASSETTE_FLOOR) },
            { value: "HOLLOW_CORE_SLAB_FLOOR", label: getBearingFloorTranslations(t, FloorBearingTypes.HOLLOW_CORE_SLAB_FLOOR) },
            { value: "COMBINATION_FLOOR", label: getBearingFloorTranslations(t, FloorBearingTypes.COMBINATION_FLOOR) },
        ].filter((x, index, array) => index === array.findIndex((t) => t.value === x.value))
        // .sort((a, b) => a.label.localeCompare(b.label));
    }, [state.bearingFloor, t]);

    const handleEditableThicknessChange = (event: SyntheticEvent<HTMLInputElement>) => {
        const input = event.target as HTMLInputElement;
        const type = input.dataset.type!;
        let thickness = isNaN(parseInt(input.value)) ? 0 : parseInt(input.value);

        let validThickness = true;
        if (!thickness) validThickness = false;

        if (type) {
            setState({
                ...state,
                [type]: {
                    ...state[type],
                    valid: validThickness,
                    material: { ...state[type].material, thickness },
                },
            });
        }
    }

    const handleEditableOptionChange = (event: SyntheticEvent<HTMLSelectElement>, EnumType: any) => {
        const input = event.target as HTMLInputElement
        const type = input.dataset.type!
        const material = parseMaterial(input.value)

        if (material) {
            setState({
                ...state,
                [type]: { ...state[type], type: getEnumValue(input.value, EnumType), material },
            });
        }
    }

    return (
        <TableBody>
            <RSRow type={"RSI"} />

            <TableSubtitleRow>{t("extendedThermalCalculation.preliminary.subtitle")}</TableSubtitleRow>
            <Separator />

            {/* Floor screed */}
            <TableRow
                type="floorScreed"
                title={t("floor.overview.floorScreed").toUpperCase()}
                text={getFloorScreedTranslations(t, state.floorScreed.type)}
                thickness={performance.floorScreed.thickness!}
                lambdaValue={formatCalc(performance.floorScreed.lambda!)}
                insulationValue={formatCalc(performance.floorScreed.RValue!)}

                editableThickness={true}
                editableThicknessValue={performance.floorScreed.thickness!}
                onEditableThicknessChange={handleEditableThicknessChange}
                editableThicknessValid={performance.floorScreed.valid}
            />

            {/* Floor insulation */}
            <TableRow
                type="floorInsulation"
                title={t("floor.overview.floorInsulation").toUpperCase()}
                text={
                    state.floorInsulation.type === FloorInsulationTypes.ENERTHERM_ALU
                        ? t("material.ENERTHERM_ALU")
                        : t("material.ENERTHERM_KR_ALU")
                }
                thickness={performance.floorInsulation.thickness!}
                lambdaValue={formatCalc(performance.floorInsulation.lambda!)}
                insulationValue={formatCalc(performance.floorInsulation.RValue!)}

                editableThickness={true}
                editableThicknessValue={performance.floorInsulation.thickness!}
                onEditableThicknessChange={handleEditableThicknessChange}
                editableThicknessValid={performance.floorInsulation.valid}
            />

            {/* Bearing floor */}
            <Separator margin="0" />
            <TableRow
                type="bearingFloor"
                title={t("floor.overview.bearingFloor").toUpperCase()}
                text={getBearingFloorTranslations(t, state.bearingFloor.type)}
                thickness={performance.bearingFloor.thickness!}
                lambdaValue={formatCalc(performance.bearingFloor.lambda!)}
                insulationValue={formatCalc(performance.bearingFloor.RValue!)}

                editableThickness={true}
                editableThicknessValue={performance.bearingFloor.thickness!}
                onEditableThicknessChange={handleEditableThicknessChange}
                editableThicknessValid={performance.bearingFloor.valid}

                options={bearingFloorOptions}
                onOptionChange={(event) => handleEditableOptionChange(event, FloorBearingTypes)}
            />

            <Separator margin="0" />

            <RSRow type={"RSE"} />
        </TableBody>
    )
}

