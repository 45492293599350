import {
  WallInsulationTypes,
  WallTypeTypes,
  OuterCavityLeafTypes,
} from "@iko-design-center/shared"
import {
  VentilationTypes,
  ExtendedWallTypeTypes,
  ExtendedOuterCavityLeafTypes,
} from "@iko-design-center/shared/lib/constants/constants"

export function getWallTypeTranslations(
  t: any,
  type: WallTypeTypes | ExtendedWallTypeTypes
) {
  switch (type) {
    case WallTypeTypes.CONCRETE_WALL:
      return t("cavityWall.wallType.concreteWall")
    case WallTypeTypes.LIME_SAND_WALL:
      return t("cavityWall.wallType.limeSandWall")
    case WallTypeTypes.HOLLOW_STONE_WALL:
      return t("cavityWall.wallType.hollowStoneWall")
    case WallTypeTypes.CROSSWOOD_WALL:
      return t("cavityWall.wallType.crosswoodWall")

    case ExtendedWallTypeTypes.EXT_HOLLOW_STONE_WALL_1100_GLUED:
      return t("material.EXT_HOLLOW_STONE_WALL_1100_GLUED")
    case ExtendedWallTypeTypes.EXT_HOLLOW_STONE_WALL_1100_BRICKED:
      return t("material.EXT_HOLLOW_STONE_WALL_1100_BRICKED")
    case ExtendedWallTypeTypes.EXT_HOLLOW_STONE_WALL_1400_GLUED:
      return t("material.EXT_HOLLOW_STONE_WALL_1400_GLUED")
    case ExtendedWallTypeTypes.EXT_HOLLOW_STONE_WALL_1400_BRICKED:
      return t("material.EXT_HOLLOW_STONE_WALL_1400_BRICKED")
    case ExtendedWallTypeTypes.EXT_CONCRETE_WALL_2400:
      return t("material.EXT_CONCRETE_WALL_2400")
    case ExtendedWallTypeTypes.EXT_CONCRETE_WALL_400:
      return t("material.EXT_CONCRETE_WALL_400")
    case ExtendedWallTypeTypes.EXT_CONCRETE_WALL_500:
      return t("material.EXT_CONCRETE_WALL_500")
    case ExtendedWallTypeTypes.EXT_CONCRETE_WALL_600:
      return t("material.EXT_CONCRETE_WALL_600")
    case ExtendedWallTypeTypes.EXT_CONCRETE_WALL_700:
      return t("material.EXT_CONCRETE_WALL_700")
    case ExtendedWallTypeTypes.EXT_CONCRETE_WALL_800:
      return t("material.EXT_CONCRETE_WALL_800")
    case ExtendedWallTypeTypes.EXT_LIME_SAND_WALL_1850:
      return t("material.EXT_LIME_SAND_WALL_1850")
    case ExtendedWallTypeTypes.EXT_CROSSWOOD_WALL:
      return t("material.EXT_CROSSWOOD_WALL")
    default:
      return ""
  }
}

export function getWallInsulationTranslations(
  t: any,
  type: WallInsulationTypes
) {
  switch (type) {
    case WallInsulationTypes.ALU_50:
      return t("material.ENERTHERM_ALU_50")
    case WallInsulationTypes.ALU_TG:
      return t("material.ENERTHERM_ALU_TG")
    default:
      return ""
  }
}

export function getWallCavityTranslations(t: any, type: VentilationTypes) {
  switch (type) {
    case VentilationTypes.NO_VENTILATION:
      return t("material.NO_VENTILATION")
    case VentilationTypes.WEAK_VENTILATION:
      return t("material.WEAK_VENTILATION")
    case VentilationTypes.STRONG_VENTILATION:
      return t("material.STRONG_VENTILATION")
    default:
      return ""
  }
}

export function getOuterCavityLeafTranslations(
  t: any,
  type: OuterCavityLeafTypes | ExtendedOuterCavityLeafTypes
) {
  switch (type) {
    case OuterCavityLeafTypes.FACADE_MASONRY:
      return t("cavityWall.outerCavityLeaf.facadeMasonry")

    case ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_1600_GLUED:
      return t("material.EXT_HOLLOW_STONE_WALL_1600_GLUED")
    case ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_1600_BRICKED:
      return t("material.EXT_HOLLOW_STONE_WALL_1600_BRICKED")
    case ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_1700_GLUED:
      return t("material.EXT_HOLLOW_STONE_WALL_1700_GLUED")
    case ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_1700_BRICKED:
      return t("material.EXT_HOLLOW_STONE_WALL_1700_BRICKED")
    case ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_1800_GLUED:
      return t("material.EXT_HOLLOW_STONE_WALL_1800_GLUED")
    case ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_1800_BRICKED:
      return t("material.EXT_HOLLOW_STONE_WALL_1800_BRICKED")
    case ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_1900_GLUED:
      return t("material.EXT_HOLLOW_STONE_WALL_1900_GLUED")
    case ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_1900_BRICKED:
      return t("material.EXT_HOLLOW_STONE_WALL_1900_BRICKED")
    case ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_2000_GLUED:
      return t("material.EXT_HOLLOW_STONE_WALL_2000_GLUED")
    case ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_2000_BRICKED:
      return t("material.EXT_HOLLOW_STONE_WALL_2000_BRICKED")
    case ExtendedOuterCavityLeafTypes.EXT_CONCRETE_WALL_2100:
      return t("material.EXT_CONCRETE_WALL_2100")
    case ExtendedOuterCavityLeafTypes.EXT_LIME_SAND_WALL_1850_LEAF:
      return t("material.EXT_LIME_SAND_WALL_1850_LEAF")
    default:
      return ""
  }
}
