import { useFlatRoof } from "../../../store/flat-roof/hooks"
import { useEffect, useState } from "react"
import { useMatrix } from "./matrix.hook"

export function useSecuringMethodsFinishing() {
  const { getRemainingCombinations } = useMatrix()
  const [finishingOptions, setFinishingOptions] = useState<any>([])
  const { configurationState, setSecuringMethodFinishing } = useFlatRoof()
  const { finishing: stateFinishing } = configurationState.securingMethods
  const {
    roofType: stateRoofType,
    roofFloor: stateRoofFloor,
    waterproofing: stateWaterproofing,
  } = configurationState

  useEffect(() => {
    if (finishingOptions.length && !stateFinishing) {
      const configFinishing = finishingOptions[0]
      if (stateFinishing !== configFinishing) {
        setSecuringMethodFinishing(configFinishing)
      }
    }
  }, [stateFinishing, setSecuringMethodFinishing, finishingOptions])

  useEffect(() => {
    // MATRIX FILTERING
    if (!finishingOptions.length) {
      const finishingOptions = getRemainingCombinations(
        stateRoofType.type,
        stateRoofType.climateClass,
        stateRoofFloor.type,
        stateWaterproofing.type
      )
        .sort((a: any) => (a.default ? -1 : 1))
        .map((x: any) => x.finishing)
      const uniqueFinishingOptions = finishingOptions.filter(
        (item, index) => finishingOptions.indexOf(item) === index
      )
      setFinishingOptions(uniqueFinishingOptions)
    }
  }, [
    getRemainingCombinations,
    stateRoofType,
    stateRoofFloor,
    stateWaterproofing,
    finishingOptions,
  ])

  function setFinishingType(value: string) {
    setSecuringMethodFinishing(value)
  }

  return {
    stateFinishing,
    finishingOptions,
    setFinishingType,
  }
}
