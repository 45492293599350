import React, { SyntheticEvent } from "react";
import styled from "styled-components";
import { isMobile } from "../../services/helpers.service";
import { styles } from "../../styles";
import { Spacer } from "./Spacer";
import { H2, P } from "./Typography";

export type OverviewItemProps = {
  title: string;
  text: string;
  suffix?: string;
  options?: Array<{ label: string; value: string }>;
  optionType?: string;
  onOptionChange?: (event: SyntheticEvent<HTMLSelectElement>) => void;
};

const NameAndTypeContainer = styled.div`
  display: flex;
  
  p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: ${20 / 16}rem;
    margin-top: 0 !important;

    @media screen and (pointer: coarse) {
      font-size: 1.2rem;
    }
  }
`;

const OverflowText = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: ${styles.colors.dark2};
  line-height: ${20 / 16}rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (pointer: coarse) {
    font-size: 1.2rem;
  }
`;

const Select = styled.select`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: ${14 / 16}rem;
  line-height: ${19 / 16}rem;
  color: ${styles.colors.darkBlue};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  
  &:focus {
    outline: none;
  }

  margin-top: 3px;
  padding: 6px 12px 5px 12px;
  border-radius: 4px;
  border: 1px solid ${styles.colors.darkBlue30};
  width: 50%;
  appearance: none;
  
  background-image: url("data:image/svg+xml;utf8,<svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.48633 5.38138V4.58138C1.48633 4.42698 1.57553 4.28618 1.71513 4.21978C1.85473 4.15338 2.01993 4.17378 2.13993 4.27178L6.28633 7.66458L10.4331 4.27178C10.5527 4.17378 10.7179 4.15378 10.8579 4.21978C10.9979 4.28578 11.0863 4.42698 11.0863 4.58138V5.38138C11.0863 5.50138 11.0323 5.61498 10.9395 5.69098L6.53953 9.29098C6.39193 9.41138 6.18033 9.41138 6.03273 9.29098L1.63273 5.69098C1.54033 5.61498 1.48633 5.50138 1.48633 5.38138Z' fill='%236E7188'/></svg>");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;
  background-size: 12px 12px;
  background-color: ${styles.colors.white};
      
  @media screen and (pointer: coarse) {
    font-size: 1.2rem;
  }
`;

const DividerLine = styled.div`
  width: 0.1rem;
  height: 0.9rem;
  opacity: 0.7;
  background: ${styles.colors.primary};
  align-self: center;
`;

export const OverviewItem = ({ title, text, suffix, options, optionType, onOptionChange }: OverviewItemProps) => {
  return (
    <div style={{ width: "100%" }}>
      <H2 fontSizePx={12} lineHeightPx={14.06} color={styles.colors.darkBlue50}>
        {title}
      </H2>
      <Spacer heightPx={2} />
      <NameAndTypeContainer>
        {options && options.length > 1 ?
          <Select onChange={onOptionChange} data-type={optionType}>
            {options.map(({ label, value }) => <option key={value} value={value}>{label}</option>)}
          </Select> :
          <OverflowText>{text}</OverflowText>
        }
        {suffix && (
          <>
            {isMobile() && <Spacer widthPx={32} />}
            {!isMobile() && <Spacer widthPx={5} />}
            {!isMobile() && (<>
              <DividerLine></DividerLine>
            </>
            )}
            {!isMobile() && <Spacer widthPx={5} />}
            <P fontSizePx={14} color={styles.colors.darkBlue75}>
              {suffix}
            </P>
          </>
        )}
      </NameAndTypeContainer>
    </div >
  );
};
