import {
  FloorHeatingTypes,
  FloorInsulationTypes,
  FloorScreedTypes,
} from "@iko-design-center/shared"
import { FloorBearingTypes } from "@iko-design-center/shared/lib/constants/constants"

export function getFloorHeatingTranslations(t: any, type: FloorHeatingTypes) {
  switch (type) {
    case FloorHeatingTypes.FLOOR_HEATING:
      return t("floor.floorHeating.floorHeating")
    case FloorHeatingTypes.NO_FLOOR_HEATING:
      return t("floor.floorHeating.noFloorHeating")
  }
}

export function getFloorInsulationTranslations(
  t: any,
  type: FloorInsulationTypes
) {
  switch (type) {
    case FloorInsulationTypes.ENERTHERM_ALU:
      return t("floor.floorInsulation.ALU")
    case FloorInsulationTypes.ENERTHERM_KR_ALU:
      return t("floor.floorInsulation.KRALU")
  }
}

export function getFloorScreedTranslations(t: any, type: FloorScreedTypes) {
  switch (type) {
    case FloorScreedTypes.ANHYDRITE_FLOOR:
      return t("floor.floorScreed.anhydrite")
    case FloorScreedTypes.CHAPE_FLOOR:
      return t("floor.floorScreed.chape")
  }
}

export function getBearingFloorTranslations(t: any, type: FloorBearingTypes) {
  switch (type) {
    case FloorBearingTypes.REINFORCED_CONCRETE_FLOOR:
      return t("material.REINFORCED_CONCRETE_FLOOR")
    case FloorBearingTypes.RIBBED_CASSETTE_FLOOR:
      return t("material.RIBBED_CASSETTE_FLOOR")
    case FloorBearingTypes.HOLLOW_CORE_SLAB_FLOOR:
      return t("material.HOLLOW_CORE_SLAB_FLOOR")
    case FloorBearingTypes.COMBINATION_FLOOR:
      return t("material.COMBINATION_FLOOR")
    default:
      return ""
  }
}
