import styled from "styled-components";
import { RSRow, Separator, TableRow, TableSubtitleRow } from "./ExtendedThermalCalculation";
import {
    getWallInsulationTranslations, getWallTypeTranslations, getWallCavityTranslations, getOuterCavityLeafTranslations
} from "../translations";
import { useThermalState } from "./ThermalStateProvider";
import { useTranslation } from "react-i18next";
import { SyntheticEvent, useMemo } from "react";
import { getEnumValue, parseMaterial } from "../../../../services/helpers.service";
import { ExtendedOuterCavityLeafTypes, ExtendedWallTypeTypes, VentilationTypes } from "@iko-design-center/shared/lib/constants/constants";
import { useExtendedThermalCalculation } from "./extendedCalculation.hook";

const TableBody = styled.div`
`;

export const PreliminaryTableBody = () => {
    const { t } = useTranslation() as any;
    const { state, setState, performance } = useThermalState();
    const { formatCalc } = useExtendedThermalCalculation();

    const wallCavityOptions = useMemo(() => {
        return [
            { value: state.wallCavity.material.id, label: getWallCavityTranslations(t, state.wallCavity.type) },
            { value: "NO_VENTILATION", label: getWallCavityTranslations(t, VentilationTypes.NO_VENTILATION) },
            { value: "WEAK_VENTILATION", label: getWallCavityTranslations(t, VentilationTypes.WEAK_VENTILATION) },
            { value: "STRONG_VENTILATION", label: getWallCavityTranslations(t, VentilationTypes.STRONG_VENTILATION) },
        ].filter((x, index, array) => index === array.findIndex((t) => t.value === x.value))
        // .sort((a, b) => a.label.localeCompare(b.label));
    }, [state.wallCavity, t]);

    const outerCavityLeafOptions = useMemo(() => {
        return [
            { value: state.outerCavityLeaf.material.id, label: getOuterCavityLeafTranslations(t, state.outerCavityLeaf.type) },
            { value: "EXT_HOLLOW_STONE_WALL_1600_GLUED", label: getOuterCavityLeafTranslations(t, ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_1600_GLUED) },
            { value: "EXT_HOLLOW_STONE_WALL_1600_BRICKED", label: getOuterCavityLeafTranslations(t, ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_1600_BRICKED) },
            { value: "EXT_HOLLOW_STONE_WALL_1700_GLUED", label: getOuterCavityLeafTranslations(t, ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_1700_GLUED) },
            { value: "EXT_HOLLOW_STONE_WALL_1700_BRICKED", label: getOuterCavityLeafTranslations(t, ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_1700_BRICKED) },
            { value: "EXT_HOLLOW_STONE_WALL_1800_GLUED", label: getOuterCavityLeafTranslations(t, ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_1800_GLUED) },
            { value: "EXT_HOLLOW_STONE_WALL_1800_BRICKED", label: getOuterCavityLeafTranslations(t, ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_1800_BRICKED) },
            { value: "EXT_HOLLOW_STONE_WALL_1900_GLUED", label: getOuterCavityLeafTranslations(t, ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_1900_GLUED) },
            { value: "EXT_HOLLOW_STONE_WALL_1900_BRICKED", label: getOuterCavityLeafTranslations(t, ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_1900_BRICKED) },
            { value: "EXT_HOLLOW_STONE_WALL_2000_GLUED", label: getOuterCavityLeafTranslations(t, ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_2000_GLUED) },
            { value: "EXT_HOLLOW_STONE_WALL_2000_BRICKED", label: getOuterCavityLeafTranslations(t, ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_2000_BRICKED) },
            { value: "EXT_CONCRETE_WALL_2100", label: getOuterCavityLeafTranslations(t, ExtendedOuterCavityLeafTypes.EXT_CONCRETE_WALL_2100) },
            { value: "EXT_LIME_SAND_WALL_1850_LEAF", label: getOuterCavityLeafTranslations(t, ExtendedOuterCavityLeafTypes.EXT_LIME_SAND_WALL_1850_LEAF) },
        ].filter((x, index, array) => index === array.findIndex((t) => t.value === x.value))
        // .sort((a, b) => a.label.localeCompare(b.label));
    }, [state.outerCavityLeaf, t]);

    const handleEditableThicknessChange = (event: SyntheticEvent<HTMLInputElement>) => {
        const input = event.target as HTMLInputElement;
        const type = input.dataset.type!;
        let thickness = isNaN(parseInt(input.value)) ? 0 : parseInt(input.value);

        let validThickness = true;
        if (!thickness) validThickness = false;

        if (type) {
            setState({
                ...state,
                [type]: {
                    ...state[type],
                    valid: validThickness,
                    material: { ...state[type].material, thickness },
                },
            });
        }
    }

    const handleEditableOptionChange = (event: SyntheticEvent<HTMLSelectElement>, EnumType: any) => {
        const input = event.target as HTMLInputElement
        const type = input.dataset.type!
        const material = parseMaterial(input.value)

        if (material) {
            setState({
                ...state,
                [type]: { ...state[type], type: getEnumValue(input.value, EnumType), material },
            });
        }
    }

    return (
        <TableBody>
            <RSRow type={"RSI"} />

            <TableSubtitleRow>{t("extendedThermalCalculation.preliminary.subtitle")}</TableSubtitleRow>
            <Separator />

            {/* Wall type */}
            <TableRow
                type="wallType"
                title={t("cavityWall.overview.wallType").toUpperCase()}
                text={getWallTypeTranslations(t, state.wallType.type)}
                thickness={performance.wallType.thickness!}
                lambdaValue={formatCalc(performance.wallType.lambda!)}
                insulationValue={formatCalc(performance.wallType.RValue!)}

                editableThickness={true}
                editableThicknessValue={performance.wallType.thickness!}
                onEditableThicknessChange={handleEditableThicknessChange}
                editableThicknessValid={performance.wallType.valid}

                options={state.wallType.options}
                onOptionChange={(event) => handleEditableOptionChange(event, ExtendedWallTypeTypes)}
            />

            {/* Wall insulation */}
            <Separator margin="0" />
            <TableRow
                classes="-even-row"
                type="wallInsulation"
                title={t("cavityWall.overview.wallInsulation").toUpperCase()}
                text={getWallInsulationTranslations(t, state.wallInsulation.type)}
                thickness={performance.wallInsulation.thickness!}
                lambdaValue={formatCalc(performance.wallInsulation.lambda!)}
                insulationValue={formatCalc(performance.wallInsulation.RValue!)}

                editableThickness={true}
                editableThicknessValue={performance.wallInsulation.thickness!}
                onEditableThicknessChange={handleEditableThicknessChange}
                editableThicknessValid={performance.wallInsulation.valid}
            />

            {/* Wall cavity */}
            <Separator margin="0" />
            <TableRow
                type="wallCavity"
                title={t("cavityWall.overview.airCavity").toUpperCase()}
                text={getWallCavityTranslations(t, state.wallCavity.type)}
                thickness={performance.wallCavity.thickness!}
                lambdaValue={undefined}
                insulationValue={formatCalc(performance.wallCavity.RValue!)}

                options={wallCavityOptions}
                onOptionChange={(event) => handleEditableOptionChange(event, VentilationTypes)}
            />

            {/* Outer cavity leaf */}
            <Separator margin="0" />
            <TableRow
                type="outerCavityLeaf"
                title={t("cavityWall.overview.outerCavityLeaf").toUpperCase()}
                text={getOuterCavityLeafTranslations(t, state.outerCavityLeaf.type)}
                thickness={performance.outerCavityLeaf.thickness!}
                lambdaValue={formatCalc(performance.outerCavityLeaf.lambda!)}
                insulationValue={formatCalc(performance.outerCavityLeaf.RValue!)}

                editableThickness={true}
                editableThicknessValue={performance.outerCavityLeaf.thickness!}
                onEditableThicknessChange={handleEditableThicknessChange}
                editableThicknessValid={performance.outerCavityLeaf.valid}

                options={outerCavityLeafOptions}
                onOptionChange={(event) => handleEditableOptionChange(event, ExtendedOuterCavityLeafTypes)}
            />

            <Separator margin="0" />

            <RSRow type={"RSE"} />
        </TableBody>
    )
}

