import styled from "styled-components";
import { useThermalState } from "./ThermalStateProvider";
import { useExtendedThermalCalculation } from "./extendedCalculation.hook";
import { useTranslation } from "react-i18next";
import { styles } from "../../../../styles";
import { H2, P } from "../../../common/Typography";

const OutcomeTableRow = styled.div`
    background: ${styles.colors.darkBlue10};
    padding: 20px 0 20px 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const TableRowLeftColumn = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0.5;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${styles.colors.black};
`;

const TableRowRightColumn = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0.1666666;
  justify-content: center;
  align-items: center;
`;

const infoStyles = {
    fontWeight: 400 as any, fontSizePx: 12, lineHeightPx: 19, color: styles.colors.black,
}
const resultStyles = {
    fontWeight: 700 as any, fontSizePx: 16, lineHeightPx: 18, color: styles.colors.dark2,
}

export const CorrectionsOutcomeTableBody = () => {
    const { performance } = useThermalState();
    const { formatCalc } = useExtendedThermalCalculation()
    const { t } = useTranslation() as any;

    return (
        <OutcomeTableRow>
            <TableRowLeftColumn>
                <H2 {...infoStyles} innerHTML={t("extendedThermalCalculation.correction.infoTitle")}> </H2>
                <P {...infoStyles}>{t("extendedThermalCalculation.ventilatedWall.correction.infoText", "")}</P>
            </TableRowLeftColumn>

            <TableRowRightColumn />
            <TableRowRightColumn />
            <TableRowRightColumn />
            <TableRowRightColumn>
                <P {...resultStyles}>{formatCalc(performance.deltaUValue!)}</P>
            </TableRowRightColumn>
        </OutcomeTableRow>
    )
}

