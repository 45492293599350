export const possibleCombinations = [
    // TWO_BITUMINOUS
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "SUMMA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "SUMMA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Loose", bottomLayer: "BASE_P3_TF_ATELIA / Loose", topLayer: "SUMMA_5 / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "POWERFLEX_5_ARF / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "POWERFLEX_5_ARF / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Loose", bottomLayer: "BASE_P3_TF_ATELIA / Loose", topLayer: "POWERFLEX_5_ARF / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "SUMMA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "SUMMA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Loose", bottomLayer: "BASE_P3_TF_ATELIA / Loose", topLayer: "SUMMA_5 / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Loose", bottomLayer: "BASE_P3_TF_ATELIA / Loose", topLayer: "POWERFLEX_5_ARF / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "POWERFLEX_5_ARF / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield:"SHIELD_ALU3_5_TMMPF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "SUMMA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield:"SHIELD_ALU3_5_TMMPF / Welding", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "SUMMA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield:"SHIELD_ALU3_5_TMMPF / Welding", insulation: "ENERTHERM_ALU_F4 / Loose", bottomLayer: "BASE_P3_TF_ATELIA / Loose", topLayer: "SUMMA_5 / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield:"SHIELD_ALU3_5_TMMPF / Welding", insulation: "ENERTHERM_ALU_F4 / Loose", bottomLayer: "BASE_P3_TF_ATELIA / Loose", topLayer: "POWERFLEX_5_ARF / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield:"SHIELD_ALU3_5_TMMPF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "POWERFLEX_5_ARF / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield:"SHIELD_ALU3_5_TMMPF / Welding", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "POWERFLEX_5_ARF / Welding", finishing: "", },
    { default: true, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "SUMMA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "SUMMA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Loose", bottomLayer: "BASE_P3_TF_ATELIA / Loose", topLayer: "SUMMA_5 / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Loose", bottomLayer: "BASE_P3_TF_ATELIA / Loose", topLayer: "POWERFLEX_5_ARF / Welding", finishing: "Ballast", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "POWERFLEX_5_ARF / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "POWERFLEX_5_ARF / Welding", finishing: "", },
    
    // TWO_BITUMINOUS_AIR_PURIFYING    
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Air_cleaning", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Air_cleaning", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: true, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Air_cleaning", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Air_cleaning", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Air_cleaning", vaporShield:"SHIELD_ALU3_5_TMMPF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Air_cleaning", vaporShield:"SHIELD_ALU3_5_TMMPF / Welding", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Air_cleaning", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Air_cleaning", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "CARRARA_5 / Welding", finishing: "", },
    
    // TWO_BITUMINOUS_AIR_PURIFYING_FLAME_FREE    
    { default: false, roofType: "Energy roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: true, roofType: "Energy roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: false, roofType: "Energy roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: true, roofType: "Energy roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Air_cleaning_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "CARRARA_5 / Welding", finishing: "", },
    
    // TWO_BITUMINOUS_FLAME_FREE
    { default: true, roofType: "Green roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "IKO_ROOFGARDEN_ALPA_GOLD / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARBON_250_5 / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "IKO_ROOFGARDEN_ALPA_GOLD / Welding", finishing: "", },
    { default: true, roofType: "Green roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARBON_250_5 / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "IKO_ROOFGARDEN_ALPA_GOLD / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "IKO_ROOFGARDEN_ALPA_GOLD / Welding", finishing: "", },
    { default: true, roofType: "Green roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARBON_250_5 / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "IKO_ROOFGARDEN_ALPA_GOLD / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: true, roofType: "Green roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "IKO_ROOFGARDEN_ALPA_GOLD / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "CARBON_250_5 / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: true, roofType: "Living roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARBON_250_5 / Welding", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: true, roofType: "Living roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARBON_250_5 / Welding", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: true, roofType: "Living roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARBON_250_5 / Welding", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: false, roofType: "Living roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: true, roofType: "Retention roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: true, roofType: "Retention roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: true, roofType: "Retention roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARBON_250_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU3_5_TMMPF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU3_5_TMMPF / Welding", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "IKO_ROOFGARDEN_PANTERA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARBON_250_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU3_5_TMMPF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARBON_250_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU3_5_TMMPF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU3_5_TMMPF / Welding", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARBON_250_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: false, roofType: "Standard roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "CARRARA_5 / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "IKO_ROOFGARDEN_SBS / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "IKO_ROOFGARDEN_SBS / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Glue", bottomLayer: "QUADRA_FSA_2_8 / Self adhering", topLayer: "IKO_ROOFGARDEN_SBS / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "", roofFloor: "Steel deck", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "IKO_ROOFGARDEN_SBS / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "", roofFloor: "Wood", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_PLUS_ALU_SA / Self adhering", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "IKO_ROOFGARDEN_SBS / Welding", finishing: "", },
    { default: false, roofType: "Green roof", climateClass: "", roofFloor: "Concrete", waterproofing: "Bituminous_double_Increased_resistance_to_flying_fire", vaporShield:"SHIELD_ALU4_TF / Welding", insulation: "ENERTHERM_ALU_F4 / Mechanical", bottomLayer: "BASE_P3_TF_ATELIA / Mechanical", topLayer: "IKO_ROOFGARDEN_SBS / Welding", finishing: "", },
]
