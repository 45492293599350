import { useFlatRoof } from "../../../store/flat-roof/hooks"
import { renderer } from "../../viewer/Renderer"
import { useEffect, useState } from "react"
import { parseMatrixStringValue } from "../../../services/helpers.service"
import { useMatrix } from "./matrix.hook"

export function useSecuringMethodsBottomLayer() {
  const { getRemainingCombinations } = useMatrix()
  const [bottomLayerOptions, setBottomLayerOptions] = useState<any>([])
  const { configurationState, setSecuringMethodBottomLayer } = useFlatRoof()
  const { bottomLayer: stateBottomLayer } = configurationState.securingMethods
  const {
    roofType: stateRoofType,
    roofFloor: stateRoofFloor,
    waterproofing: stateWaterproofing,
  } = configurationState

  useEffect(() => {
    if (bottomLayerOptions.length && !stateBottomLayer) {
      const configBottomLayer = bottomLayerOptions[0]
      if (stateBottomLayer !== configBottomLayer) {
        setSecuringMethodBottomLayer(configBottomLayer)
        renderer.displayBottomLayerMaterial(configBottomLayer.type)
        renderer.displaySecuringMethod(
          "ONDERLAAG-BEVESTIGING",
          configBottomLayer.method
        )
      }
    }
  }, [stateBottomLayer, setSecuringMethodBottomLayer, bottomLayerOptions])

  useEffect(() => {
    // MATRIX FILTERING
    if (!bottomLayerOptions.length) {
      const bottomLayerOptions = getRemainingCombinations(
        stateRoofType.type,
        stateRoofType.climateClass,
        stateRoofFloor.type,
        stateWaterproofing.type
      )
        .sort((a: any) => (a.default ? -1 : 1))
        .map((x: any) => x.bottomLayer)
      const uniqueBottomLayerOptions = bottomLayerOptions
        .filter((item, index) => bottomLayerOptions.indexOf(item) === index)
        .map((x: any) => parseMatrixStringValue(x))
      setBottomLayerOptions(uniqueBottomLayerOptions)
    }
  }, [
    getRemainingCombinations,
    stateRoofType,
    stateRoofFloor,
    stateWaterproofing,
    bottomLayerOptions,
  ])

  function setBottomLayerType(value: string) {
    const option = parseMatrixStringValue(value)
    setSecuringMethodBottomLayer(option)
    if (option) {
      renderer.displayBottomLayerMaterial(option.type)
      renderer.displaySecuringMethod("ONDERLAAG-BEVESTIGING", option.method)
    }
  }

  return {
    stateBottomLayer,
    bottomLayerOptions,
    setBottomLayerType,
  }
}
