"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialFlatRoofThermalPerformanceState = exports.FLAT_ROOF_MINIMUM_INSULATION_THICKNESS = exports.EXTENDED_FLAT_ROOF_R_VALUE_RSE = exports.EXTENDED_FLAT_ROOF_R_VALUE_RSI = exports.FLAT_ROOF_R_VALUE_RSE = exports.FLAT_ROOF_R_VALUE_RSI = void 0;
exports.FLAT_ROOF_R_VALUE_RSI = 0.1;
exports.FLAT_ROOF_R_VALUE_RSE = 0.04;
exports.EXTENDED_FLAT_ROOF_R_VALUE_RSI = 0.1;
exports.EXTENDED_FLAT_ROOF_R_VALUE_RSE = 0.04;
exports.FLAT_ROOF_MINIMUM_INSULATION_THICKNESS = 20;
exports.initialFlatRoofThermalPerformanceState = {
    UCValue: null,
    RTValue: null,
    topLayer: {
        thickness: null,
        lambda: null,
        RValue: null,
    },
    bottomLayer: {
        thickness: null,
        lambda: null,
        RValue: null,
    },
    insulation: {
        thickness: null,
        lambda: null,
        RValue: null,
    },
    vaporShield: {
        thickness: null,
        lambda: null,
        RValue: null,
    },
    roofFloor: {
        thickness: null,
        lambda: null,
        RValue: null,
    },
};
