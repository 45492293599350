import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { resizeImage } from "../../../services/screenshot.service"
import { useVentilatedWall } from "../../../store/ventilated-wall/hooks"
import { useModal } from "../../../store/modal/hooks"
import { renderer } from "../../viewer/Renderer"
import { useApplication } from "../../../store/application/hooks"
import { isMobile } from "../../../services/helpers.service"

type VisibleState = {
  wallType: boolean
  facadeCladding: boolean
  wallInsulation: boolean
  connectionProfile: boolean
  backfaceConstruction: boolean
  details: boolean
}

export function useOverview() {
  const { t, i18n } = useTranslation() as any
  const [, country] = i18n.language.split("_")
  const showExtendedThermalCalculation = country === "nl" && !isMobile()
  const {
    configurationState: { wallType, facadeCladding, wallInsulation },
    thermalPerformance,
    setOverviewScreenshot,
    setHumanReadableName,
  } = useVentilatedWall()

  const wallTypeType = wallType.type!
  const facadeCladdingType = facadeCladding.type!
  const wallInsulationType = wallInsulation.type!

  const {
    displayVentilatedWallDownloadModal,
    displayVentilatedWallThermalCalculationModal,
    displayVentilatedWallExtendedThermalCalculationModal,
    displayRequestAdviceModal,
  } = useModal()
  const { currentModel, applicationType } = useApplication()

  const initialVisibleState = useMemo(
    () => ({
      wallType: true,
      facadeCladding: true,
      wallInsulation: true,
      connectionProfile: true,
      backfaceConstruction: true,
      details: true,
    }),
    []
  )
  const [visibleState, setVisibleState] = useState<VisibleState>(
    initialVisibleState
  )
  const humanReadableName = `${wallType.type} / ${facadeCladding.type} / ${wallInsulation.type}`

  useEffect(() => {
    setHumanReadableName(humanReadableName)
  }, [humanReadableName, setHumanReadableName])

  useEffect(
    () => {
      if (!isMobile()) renderer.displayMarkers()
      const dataURL = renderer.createScreenshot()
      resizeImage(dataURL, 520, 520).then((resizedDataURL) => {
        setOverviewScreenshot(resizedDataURL)
      })

      return () => {
        renderer.hideMarkers()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setOverviewScreenshot]
  )

  function toggleVisibleState(key: keyof VisibleState) {
    setVisibleState({ ...visibleState, [key]: !visibleState[key] })
  }

  useEffect(() => {
    setVisibleState(initialVisibleState)
    if (!isMobile()) renderer.displayMarkers()
    renderer.toggleAllLayers(applicationType, true)
  }, [currentModel, setVisibleState, initialVisibleState, applicationType])

  function handleToggleClick(toggle = "wallType", layer = "BINNENSPOUWBLAD") {
    toggleVisibleState(toggle as keyof VisibleState)
    renderer.toggleLayers(layer)
  }

  function handleDetailsClick() {
    toggleVisibleState("details")
    renderer.toggleDetails()
  }

  function handleAdjustSpecificationsClick() {
    if (showExtendedThermalCalculation) {
      displayVentilatedWallExtendedThermalCalculationModal()
    } else {
      displayVentilatedWallThermalCalculationModal()
    }
  }

  function handleDownloadFilesClick() {
    displayVentilatedWallDownloadModal()
  }

  function handleRequestAdviceClick() {
    displayRequestAdviceModal()
  }

  return {
    t,
    currentModel,
    thermalPerformance,
    visibleState,

    wallTypeType,
    facadeCladdingType,
    wallInsulationType,

    handleToggleClick,
    handleDetailsClick,

    handleAdjustSpecificationsClick,
    handleDownloadFilesClick,
    handleRequestAdviceClick,
  }
}
