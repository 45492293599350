import {
    EXTENDED_FLAT_ROOF_R_VALUE_RSI,
    EXTENDED_FLAT_ROOF_R_VALUE_RSE,
} from "@iko-design-center/shared";
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useFlatRoof } from "../../../../store/flat-roof/hooks";
import { useExtendedThermalCalculation } from "./extendedCalculation.hook";
import { parseMaterial } from "../../../../services/helpers.service";
import { RoofFloorTypes, ExtendedRoofFloorTypes, ExtendedVaporShieldTypes, ExtendedInsulationTypes, ExtendedTopLayerTypes } from "@iko-design-center/shared/lib/constants/constants";
import { getRoofFloorTranslations } from "../translations";
import { useTranslation } from "react-i18next";

type State = any
type ThermalStateProviderProps = { children: ReactNode }
const ThermalStateContext = createContext<State | undefined>(undefined)

const getTopLayerFixedThermalPerformance = (topLayerType: ExtendedTopLayerTypes) => {
    const data: {
        lambda: number | null; thickness: any; RValue: number | null; valid: boolean;
    } = {
        RValue: 0.00,
        lambda: 0.00,
        thickness: 0,
        valid: true,
    }
    switch (topLayerType) {
        case ExtendedTopLayerTypes.EXT_BITUMINOUS_MEMBRANE:
            data.RValue = 0.060
            return data;
        case ExtendedTopLayerTypes.EXT_EPDM:
            data.RValue = 0.060
            return data;
        case ExtendedTopLayerTypes.EXT_PVC:
            data.RValue = 0.060
            return data;
    }
}

function ThermalStateProvider({ children }: ThermalStateProviderProps) {
    const { t } = useTranslation() as any
    const { configurationState } = useFlatRoof()
    const {
        calculateMaterialThermalPerformance,
        calculatePreliminaryThermalPerformance,
        calculateFixationCorrection,
        calculateCorrection,
        calculateTotalsThermalPerformance,
    } = useExtendedThermalCalculation()

    const initialState: any = {
        roofFloor: {
            type: configurationState.roofFloor.type,
            material: null,
        },
        roofVaporShield: {
            type: ExtendedVaporShieldTypes.EXT_PE_FOIL_VAPORSHIELD,
            material: parseMaterial('EXT_PE_FOIL_VAPORSHIELD'),
        },
        roofInsulationOne: {
            type: ExtendedInsulationTypes.EXT_ENERTHERM_ALU_ALU_F4,
            material: parseMaterial('EXT_ENERTHERM_ALU_ALU_F4'),
        },
        roofInsulationTwo: {
            type: ExtendedInsulationTypes.EXT_ENERTHERM_NONE,
            material: parseMaterial('EXT_ENERTHERM_NONE'),
        },
        roofToplayer: {
            type: ExtendedTopLayerTypes.EXT_BITUMINOUS_MEMBRANE,
            material: parseMaterial('EXT_BITUMINOUS_MEMBRANE'),
        }
    }

    // Roof floor
    switch (configurationState.roofFloor.type) {
        case RoofFloorTypes.WOOD:
            initialState.roofFloor = {
                type: ExtendedRoofFloorTypes.EXT_OSB_ROOF, material: parseMaterial('EXT_OSB_ROOF'), options: [{ value: "EXT_OSB_ROOF", label: getRoofFloorTranslations(t, ExtendedRoofFloorTypes.EXT_OSB_ROOF) }, { value: "EXT_MULTIPLEX_ROOF_500", label: getRoofFloorTranslations(t, ExtendedRoofFloorTypes.EXT_MULTIPLEX_ROOF_500) },]
            }
            break
        case RoofFloorTypes.CONCRETE:
            initialState.roofFloor = {
                type: ExtendedRoofFloorTypes.EXT_CONCRETE_ROOF_2400, material: parseMaterial('EXT_CONCRETE_ROOF_2400'), options: [{ value: "EXT_CONCRETE_ROOF_2400", label: getRoofFloorTranslations(t, ExtendedRoofFloorTypes.EXT_CONCRETE_ROOF_2400) },]
            }
            break
        case RoofFloorTypes.STEEL_DECK:
            initialState.roofFloor = {
                type: ExtendedRoofFloorTypes.EXT_STEEL_DECK_ROOF, material: parseMaterial('EXT_STEEL_DECK_ROOF'), option: [{ value: "EXT_STEEL_DECK_ROOF", label: getRoofFloorTranslations(t, ExtendedRoofFloorTypes.EXT_STEEL_DECK_ROOF) },]
            }
            break
    }

    const initialPerformance = {
        roofFloor: calculateMaterialThermalPerformance(initialState.roofFloor.material),
        roofVaporShield: calculateMaterialThermalPerformance(initialState.roofVaporShield.material),
        roofInsulationOne: calculateMaterialThermalPerformance(initialState.roofInsulationOne.material),
        roofInsulationTwo: calculateMaterialThermalPerformance(initialState.roofInsulationTwo.material),
        roofToplayer: getTopLayerFixedThermalPerformance(initialState.roofToplayer.type),
        UTValue: null as number | null,
        RTValue: null as number | null,
        convectionUValue: null as number | null,
        fixationUValue: null as number | null,
        deltaUValue: null as number | null,
        UCValue: null as number | null,
        RCValue: null as number | null,
    }

    const initialCorrections = {
        fixation: {
            active: false,
            type: "GALVANIZED_STEEL_FIXATION",
            lambda: 50,
            diameter: 4.8,
            count: 5.25,
            dtule: 115,
            dfa: 25,
        },
    }

    const initialConstants = {
        RSI: { label: 'RSI', RValue: EXTENDED_FLAT_ROOF_R_VALUE_RSI },
        RSE: { label: 'RSE', RValue: EXTENDED_FLAT_ROOF_R_VALUE_RSE },
    }

    const [state, setState] = useState(initialState)
    const [performance, setPerformance] = useState(initialPerformance)
    const [corrections, setCorrections] = useState(initialCorrections)
    const [constants, setConstants] = useState(initialConstants)

    useEffect(() => {
        // Preliminary
        const roofFloorThermalPerformance = calculateMaterialThermalPerformance(state.roofFloor.material)
        const roofVaporShieldThermalPerformance = calculateMaterialThermalPerformance(state.roofVaporShield.material, 1)
        const roofInsulationOneThermalPerformance = calculateMaterialThermalPerformance(state.roofInsulationOne.material)
        const roofInsulationTwoThermalPerformance = calculateMaterialThermalPerformance(state.roofInsulationTwo.material)
        const roofToplayerThermalPerformance = getTopLayerFixedThermalPerformance(state.roofToplayer.type)

        const [RTValue, UTValue] = calculatePreliminaryThermalPerformance(
            [
                roofFloorThermalPerformance,
                roofVaporShieldThermalPerformance,
                roofInsulationOneThermalPerformance,
                roofInsulationTwoThermalPerformance,
                roofToplayerThermalPerformance
            ],
            initialConstants.RSI.RValue,
            initialConstants.RSE.RValue
        )
        // Corrections
        const convectionUValue = 0
        let fixationUValue = 0
        if (corrections?.fixation?.active) {
            fixationUValue = calculateFixationCorrection({
                fixationLambda: corrections.fixation.lambda,
                fixationDiameter: corrections.fixation.diameter,
                fixationCount: corrections.fixation.count,
                fixationDtule: corrections.fixation.dtule,
                fixationDfa: corrections.fixation.dfa,
                insulationD: roofInsulationOneThermalPerformance.thickness,
                insulationR: roofInsulationOneThermalPerformance.RValue,
                RT: RTValue,
            })
        }
        const deltaUValue = calculateCorrection(convectionUValue, fixationUValue, UTValue)
        // Totals
        const [RCValue, UCValue] = calculateTotalsThermalPerformance(
            UTValue,
            deltaUValue,
            initialConstants.RSI.RValue,
            initialConstants.RSE.RValue
        )

        const newThermalPerformance = {
            ...performance,
            roofFloor: roofFloorThermalPerformance,
            roofVaporShield: roofVaporShieldThermalPerformance,
            roofInsulationOne: roofInsulationOneThermalPerformance,
            roofInsulationTwo: roofInsulationTwoThermalPerformance,
            roofToplayer: roofToplayerThermalPerformance,
            RTValue,
            UTValue,
            convectionUValue,
            fixationUValue,
            deltaUValue,
            UCValue,
            RCValue
        }

        setPerformance(newThermalPerformance)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, corrections])

    return (
        <ThermalStateContext.Provider value={{
            state, setState,
            performance, setPerformance,
            corrections, setCorrections,
            constants, setConstants
        }}>
            {children}
        </ThermalStateContext.Provider>
    )
}

function useThermalState() {
    const context = useContext(ThermalStateContext)
    if (context === undefined) {
        throw new Error('useThermalState must be used within a ThermalStateProvider')
    }
    return context
}

export { ThermalStateProvider, useThermalState }
