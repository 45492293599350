"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SolLayers = exports.SolMaterialMap = exports.SolBuildingTypeTypes = exports.SolConformityTypes = exports.SolAcousticLayerTypes = exports.SolNoiseLevelTypes = exports.SolInsulationTypes = exports.FloorLayers = exports.FloorMaterialMap = exports.FloorBearingTypes = exports.FloorScreedTypes = exports.FloorInsulationTypes = exports.FloorHeatingTypes = exports.VentilatedWallLayers = exports.ventilatedWallMaterialMap = exports.VentilatedWallVaporShieldTypes = exports.VentilatedWallInsulationTypes = exports.VentilatedWallFacadeCladdingTypes = exports.ExtendedVentilatedWallWallTypeTypes = exports.VentilatedWallWallTypeTypes = exports.SlopedRoofLayers = exports.slopedRoofMaterialMap = exports.SlopedRoofInsulationTypeTypes = exports.SlopedRoofTypeTypes = exports.CavityWallLayers = exports.cavityWallMaterialMap = exports.VentilationTypes = exports.ExtendedOuterCavityLeafTypes = exports.OuterCavityLeafTypes = exports.WallInsulationTypes = exports.ExtendedWallTypeTypes = exports.WallTypeTypes = exports.FileType = exports.RoofDetails = exports.SecuringMethodTypes = exports.ExtendedTopLayerTypes = exports.TopLayerTypes = exports.BottomLayerTypes = exports.ExtendedInsulationTypes = exports.InsulationTypes = exports.ExtendedVaporShieldTypes = exports.VaporShieldTypes = exports.WaterproofingTypes = exports.ExtendedRoofFloorTypes = exports.RoofFloorTypes = exports.RoofTypeClimateClasses = exports.RoofTypeTypes = exports.Layers = exports.MaterialIDs = void 0;
var MaterialIDs;
(function (MaterialIDs) {
    // FLAT ROOF
    // ROOF FLOOR
    MaterialIDs["ROOF_FLOOR_WOOD"] = "ROOF_FLOOR_WOOD";
    MaterialIDs["ROOF_FLOOR_CONCRETE"] = "ROOF_FLOOR_CONCRETE";
    MaterialIDs["ROOF_FLOOR_STEEL_DECK"] = "ROOF_FLOOR_STEEL_DECK";
    MaterialIDs["WOOD"] = "WOOD";
    MaterialIDs["CONCRETE"] = "CONCRETE";
    MaterialIDs["STEEL_DECK"] = "STEEL_DECK";
    // VAPOR SHIELD - BE
    MaterialIDs["CARBON_PANTERA"] = "CARBON_PANTERA";
    MaterialIDs["BASE_P3_FT"] = "BASE_P3_FT";
    MaterialIDs["BASE_P3_TF"] = "BASE_P3_TF";
    MaterialIDs["BASE_V3_TF"] = "BASE_V3_TF";
    MaterialIDs["BASE_STICK_TSA"] = "BASE_STICK_TSA";
    MaterialIDs["P3_ATELIA"] = "P3_ATELIA";
    MaterialIDs["P3_SBS_ATELIA"] = "P3_SBS_ATELIA";
    MaterialIDs["SHIELD_ALU3_TF"] = "SHIELD_ALU3_TF";
    MaterialIDs["SHIELD_COMPACT_ALU3"] = "SHIELD_COMPACT_ALU3";
    MaterialIDs["SHIELD_PRO_ALU_SA"] = "SHIELD_PRO_ALU_SA";
    // VAPOR SHIELD - NL
    MaterialIDs["BASE_P14"] = "BASE_P14";
    MaterialIDs["SHIELD_ALU4_TF"] = "SHIELD_ALU4_TF";
    MaterialIDs["COMPACT_ALU3"] = "COMPACT_ALU3";
    // VAPOR SHIELD - DE
    MaterialIDs["SHIELD_ALU3_5_TMMPF"] = "SHIELD_ALU3_5_TMMPF";
    MaterialIDs["SHIELD_PLUS_ALU_SA"] = "SHIELD_PLUS_ALU_SA";
    MaterialIDs["SHIELD_ALU_SA"] = "SHIELD_ALU_SA";
    // INSULATION - BE & NL
    MaterialIDs["ENERTHERM_ATELIA"] = "ENERTHERM_ATELIA";
    MaterialIDs["ENERTHERM_ALU"] = "ENERTHERM_ALU";
    MaterialIDs["ENERTHERM_MG"] = "ENERTHERM_MG";
    // INSULATION - DE
    MaterialIDs["ENERTHERM_ALU_F4"] = "ENERTHERM_ALU_F4";
    // BOTTOM LAYER - BE
    MaterialIDs["QUADRA_FSA"] = "QUADRA_FSA";
    MaterialIDs["BASE_V3_TT"] = "BASE_V3_TT";
    MaterialIDs["BASE_P3_SBS_TF_ATELIA"] = "BASE_P3_SBS_TF_ATELIA";
    MaterialIDs["BASE_P3_TF_ATELIA"] = "BASE_P3_TF_ATELIA";
    // BOTTOM LAYER - NL
    MaterialIDs["BASE_460P60"] = "BASE_460P60";
    MaterialIDs["BASE_360P60"] = "BASE_360P60";
    MaterialIDs["BASE_460P60_ATELIA"] = "BASE_460P60_ATELIA";
    MaterialIDs["BASE_360P60_ATELIA"] = "BASE_360P60_ATELIA";
    // BOTTOM LAYER - DE
    MaterialIDs["BASE_STICK_FSA"] = "BASE_STICK_FSA";
    MaterialIDs["QUADRA_FSA_2_8"] = "QUADRA_FSA_2_8";
    // TOP LAYER - BE
    MaterialIDs["CARRARA"] = "CARRARA";
    MaterialIDs["CARBON_250"] = "CARBON_250";
    MaterialIDs["CARBON"] = "CARBON";
    MaterialIDs["PANTERA"] = "PANTERA";
    MaterialIDs["IKO_ROOFGARDEN_PANTERA"] = "IKO_ROOFGARDEN_PANTERA";
    MaterialIDs["IKO_ROOFGARDEN_4_APP_ADF"] = "IKO_ROOFGARDEN_4_APP_ADF";
    MaterialIDs["POWERGUM_470K14_ATELIA"] = "POWERGUM_470K14_ATELIA";
    MaterialIDs["POWERGUM_4AWF_ATELIA"] = "POWERGUM_4AWF_ATELIA";
    MaterialIDs["CARRARA_TECNO_SN"] = "CARRARA_TECNO_SN";
    MaterialIDs["SUMMA"] = "SUMMA";
    MaterialIDs["POWERGUM_4_ADF"] = "POWERGUM_4_ADF";
    MaterialIDs["POWERFLEX_4_ADF"] = "POWERFLEX_4_ADF";
    MaterialIDs["SECURA"] = "SECURA";
    MaterialIDs["TANETECH"] = "TANETECH";
    MaterialIDs["METATECH"] = "METATECH";
    MaterialIDs["HYBRITECH"] = "HYBRITECH";
    // TOP LAYER - NL
    MaterialIDs["CARBON_HI_SPEED"] = "CARBON_HI_SPEED";
    MaterialIDs["IKO_ROOFGARDEN_470K14"] = "IKO_ROOFGARDEN_470K14";
    MaterialIDs["POWERGUM_470K14"] = "POWERGUM_470K14";
    MaterialIDs["POWERGUM_470K24_DGRIJS"] = "POWERGUM_470K24_DGRIJS";
    MaterialIDs["POWERGUM_470K24_WIT"] = "POWERGUM_470K24_WIT";
    // TOP LAYER - DE
    MaterialIDs["POWERFLEX_5_ARF"] = "POWERFLEX_5_ARF";
    MaterialIDs["IKO_ROOFGARDEN_SBS"] = "IKO_ROOFGARDEN_SBS";
    MaterialIDs["IKO_ROOFGARDEN_ALPA_GOLD"] = "IKO_ROOFGARDEN_ALPA_GOLD";
    MaterialIDs["IKO_ROOFGARDEN_PANTERA_5"] = "IKO_ROOFGARDEN_PANTERA_5";
    MaterialIDs["CARBON_250_5"] = "CARBON_250_5";
    MaterialIDs["CARRARA_5"] = "CARRARA_5";
    MaterialIDs["SUMMA_5"] = "SUMMA_5";
    // CAVITY WALL
    MaterialIDs["CONCRETE_WALL"] = "CONCRETE_WALL";
    MaterialIDs["LIME_SAND_WALL"] = "LIME_SAND_WALL";
    MaterialIDs["HOLLOW_STONE_WALL"] = "HOLLOW_STONE_WALL";
    MaterialIDs["CROSSWOOD_WALL"] = "CROSSWOOD_WALL";
    MaterialIDs["ENERTHERM_ALU_TG"] = "ENERTHERM_ALU_TG";
    MaterialIDs["ENERTHERM_ALU_50"] = "ENERTHERM_ALU_50";
    MaterialIDs["FACADE_MASONRY"] = "FACADE_MASONRY";
    MaterialIDs["NO_VENTILATION"] = "NO_VENTILATION";
    MaterialIDs["WEAK_VENTILATION"] = "WEAK_VENTILATION";
    MaterialIDs["STRONG_VENTILATION"] = "STRONG_VENTILATION";
    // SLOPED ROOF
    MaterialIDs["TILED_ROOF"] = "TILED_ROOF";
    MaterialIDs["SLATED_ROOF"] = "SLATED_ROOF";
    MaterialIDs["ENERTHERM_ALU_NF"] = "ENERTHERM_ALU_NF";
    MaterialIDs["ENERTHERM_ALU_NF_PRO"] = "ENERTHERM_ALU_NF_PRO";
    // VENTILATED WALL
    MaterialIDs["ETERNIT_CEDRAL"] = "ETERNIT_CEDRAL";
    MaterialIDs["ETERNIT_EQUITONE"] = "ETERNIT_EQUITONE";
    MaterialIDs["TRESPA_CLADDING"] = "TRESPA_CLADDING";
    MaterialIDs["ROCKPANEL_CLADDING"] = "ROCKPANEL_CLADDING";
    MaterialIDs["SWISS_PEARL_CLADDING"] = "SWISS_PEARL_CLADDING";
    MaterialIDs["FIBERPLAST_CLADDING"] = "FIBERPLAST_CLADDING";
    MaterialIDs["KERALIT_CLADDING"] = "KERALIT_CLADDING";
    MaterialIDs["FIBER_CEMENT_CLADDING"] = "FIBER_CEMENT_CLADDING";
    MaterialIDs["WOOD_CLADDING"] = "WOOD_CLADDING";
    MaterialIDs["METAL_CLADDING"] = "METAL_CLADDING";
    MaterialIDs["COMPOSITE_CLADDING"] = "COMPOSITE_CLADDING";
    MaterialIDs["OTHER_CLADDING"] = "OTHER_CLADDING";
    MaterialIDs["ENERTHERM_ALU_PURE"] = "ENERTHERM_ALU_PURE";
    MaterialIDs["WATERPROOF_VAPOR_SHIELD"] = "WATERPROOF_VAPOR_SHIELD";
    MaterialIDs["NO_WATERPROOF_VAPOR_SHIELD"] = "NO_WATERPROOF_VAPOR_SHIELD";
    MaterialIDs["CEMENT_WINDBREAKER"] = "CEMENT_WINDBREAKER";
    // FLOOR
    MaterialIDs["FLOOR_HEATING"] = "FLOOR_HEATING";
    MaterialIDs["NO_FLOOR_HEATING"] = "NO_FLOOR_HEATING";
    MaterialIDs["ENERTHERM_KR_ALU"] = "ENERTHERM_KR_ALU";
    MaterialIDs["ANHYDRITE_FLOOR"] = "ANHYDRITE_FLOOR";
    MaterialIDs["CHAPE_FLOOR"] = "CHAPE_FLOOR";
    // SOL
    MaterialIDs["ENERTHERM_CHAPE_TG"] = "ENERTHERM_CHAPE_TG";
    MaterialIDs["ENERTHERM_KR_ALU_SOUSDALLAGE"] = "ENERTHERM_KR_ALU_SOUSDALLAGE";
    MaterialIDs["ENERTHERM_KR_ALU_DALLEPORTEE"] = "ENERTHERM_KR_ALU_DALLEPORTEE";
})(MaterialIDs = exports.MaterialIDs || (exports.MaterialIDs = {}));
// FLAT ROOF
var Layers;
(function (Layers) {
    Layers["topLayer"] = "topLayer";
    Layers["bottomLayer"] = "bottomLayer";
    Layers["insulation"] = "insulation";
    Layers["vaporShield"] = "vaporShield";
    Layers["roofFloor"] = "roofFloor";
})(Layers = exports.Layers || (exports.Layers = {}));
// The values are not uppercase as per Typescript convention.
// They are used to validate the input in Airtable.
var RoofTypeTypes;
(function (RoofTypeTypes) {
    RoofTypeTypes["ENERGY_ROOF"] = "Energy roof";
    RoofTypeTypes["LIVING_ROOF"] = "Living roof";
    RoofTypeTypes["GREEN_ROOF"] = "Green roof";
    RoofTypeTypes["STANDARD_ROOF"] = "Standard roof";
    RoofTypeTypes["RETENTION_ROOF"] = "Retention roof";
})(RoofTypeTypes = exports.RoofTypeTypes || (exports.RoofTypeTypes = {}));
var RoofTypeClimateClasses;
(function (RoofTypeClimateClasses) {
    RoofTypeClimateClasses["CLIMATE_CLASS_3"] = "I-III";
    RoofTypeClimateClasses["CLIMATE_CLASS_4"] = "IV";
})(RoofTypeClimateClasses = exports.RoofTypeClimateClasses || (exports.RoofTypeClimateClasses = {}));
var RoofFloorTypes;
(function (RoofFloorTypes) {
    RoofFloorTypes["WOOD"] = "Wood";
    RoofFloorTypes["CONCRETE"] = "Concrete";
    RoofFloorTypes["STEEL_DECK"] = "Steel deck";
})(RoofFloorTypes = exports.RoofFloorTypes || (exports.RoofFloorTypes = {}));
var ExtendedRoofFloorTypes;
(function (ExtendedRoofFloorTypes) {
    // Human readable names
    ExtendedRoofFloorTypes["EXT_CONCRETE_ROOF_2400"] = "Concrete roof 2400";
    ExtendedRoofFloorTypes["EXT_STEEL_DECK_ROOF"] = "Steel deck roof";
    ExtendedRoofFloorTypes["EXT_OSB_ROOF"] = "OSB";
    ExtendedRoofFloorTypes["EXT_MULTIPLEX_ROOF_500"] = "Multiplex 500";
})(ExtendedRoofFloorTypes = exports.ExtendedRoofFloorTypes || (exports.ExtendedRoofFloorTypes = {}));
var WaterproofingTypes;
(function (WaterproofingTypes) {
    // Old
    WaterproofingTypes["BITUMINOUS"] = "Bituminous";
    // Double Bituminous
    WaterproofingTypes["TWO_BITUMINOUS"] = "Bituminous_double";
    WaterproofingTypes["TWO_BITUMINOUS_AIR_PURIFYING"] = "Bituminous_double_Air_cleaning";
    WaterproofingTypes["TWO_BITUMINOUS_AIR_PURIFYING_CIRCULAIR"] = "Bituminous_double_Circular_Air_cleaning";
    WaterproofingTypes["TWO_BITUMINOUS_AIR_PURIFYING_FLAME_FREE"] = "Bituminous_double_Air_cleaning_Increased_resistance_to_flying_fire";
    WaterproofingTypes["TWO_BITUMINOUS_AIR_PURIFYING_CIRCULAIR_FLAME_FREE"] = "Bituminous_double_Circular_Air_cleaning_Increased_resistance_to_flying_fire";
    WaterproofingTypes["TWO_BITUMINOUS_CIRCULAIR"] = "Bituminous_double_Circular";
    WaterproofingTypes["TWO_BITUMINOUS_FLAME_FREE"] = "Bituminous_double_Increased_resistance_to_flying_fire";
    // Single Bituminous
    WaterproofingTypes["ONE_BITUMINOUS_AIR_PURIFYING_CIRCULAIR"] = "Bituminous_single_Circular_Air_cleaning";
    WaterproofingTypes["ONE_BITUMINOUS_FLAME_FREE"] = "Bituminous_single_Flame_free_installation";
    // Liquid
    WaterproofingTypes["LIQUID"] = "Liquid";
})(WaterproofingTypes = exports.WaterproofingTypes || (exports.WaterproofingTypes = {}));
var VaporShieldTypes;
(function (VaporShieldTypes) {
    // BE
    VaporShieldTypes["BASE_P3_TF"] = "BASE_P3_TF";
    VaporShieldTypes["BASE_V3_TF"] = "BASE_V3_TF";
    VaporShieldTypes["BASE_STICK_TSA"] = "BASE_STICK_TSA";
    VaporShieldTypes["P3_ATELIA"] = "P3_ATELIA";
    VaporShieldTypes["P3_SBS_ATELIA"] = "P3_SBS_ATELIA";
    VaporShieldTypes["SHIELD_ALU3_TF"] = "SHIELD_ALU3_TF";
    VaporShieldTypes["SHIELD_COMPACT_ALU3"] = "SHIELD_COMPACT_ALU3";
    VaporShieldTypes["SHIELD_PRO_ALU_SA"] = "SHIELD_PRO_ALU_SA";
    // NL
    VaporShieldTypes["BASE_P14"] = "BASE_P14";
    VaporShieldTypes["SHIELD_ALU4_TF"] = "SHIELD_ALU4_TF";
    VaporShieldTypes["COMPACT_ALU3"] = "COMPACT_ALU3";
    // DE
    VaporShieldTypes["SHIELD_ALU3_5_TMMPF"] = "SHIELD_ALU3_5_TMMPF";
    VaporShieldTypes["SHIELD_PLUS_ALU_SA"] = "SHIELD_PLUS_ALU_SA";
    VaporShieldTypes["SHIELD_ALU_SA"] = "SHIELD_ALU_SA";
})(VaporShieldTypes = exports.VaporShieldTypes || (exports.VaporShieldTypes = {}));
var ExtendedVaporShieldTypes;
(function (ExtendedVaporShieldTypes) {
    ExtendedVaporShieldTypes["EXT_PE_FOIL_VAPORSHIELD"] = "EXT_PE_FOIL_VAPORSHIELD";
    ExtendedVaporShieldTypes["EXT_BITUMINOUS_VAPORSHIELD"] = "EXT_BITUMINOUS_VAPORSHIELD";
})(ExtendedVaporShieldTypes = exports.ExtendedVaporShieldTypes || (exports.ExtendedVaporShieldTypes = {}));
var InsulationTypes;
(function (InsulationTypes) {
    // BE & NL
    InsulationTypes["ENERTHERM_ATELIA"] = "ENERTHERM_ATELIA";
    InsulationTypes["ENERTHERM_ALU"] = "ENERTHERM_ALU";
    InsulationTypes["ENERTHERM_MG"] = "ENERTHERM_MG";
    // DE
    InsulationTypes["ENERTHERM_ALU_F4"] = "ENERTHERM_ALU_F4";
})(InsulationTypes = exports.InsulationTypes || (exports.InsulationTypes = {}));
var ExtendedInsulationTypes;
(function (ExtendedInsulationTypes) {
    ExtendedInsulationTypes["EXT_ENERTHERM_ALU_ALU_F4"] = "EXT_ENERTHERM_ALU_ALU_F4";
    ExtendedInsulationTypes["EXT_ENERTHERM_ATELIA"] = "EXT_ENERTHERM_ATELIA";
    ExtendedInsulationTypes["EXT_ENERTHERM_BM"] = "EXT_ENERTHERM_BM";
    ExtendedInsulationTypes["EXT_ENERTHERM_MG"] = "EXT_ENERTHERM_MG";
    ExtendedInsulationTypes["EXT_ENERTHERM_NONE"] = "EXT_ENERTHERM_NONE";
})(ExtendedInsulationTypes = exports.ExtendedInsulationTypes || (exports.ExtendedInsulationTypes = {}));
var BottomLayerTypes;
(function (BottomLayerTypes) {
    // BE
    BottomLayerTypes["QUADRA_FSA"] = "QUADRA_FSA";
    BottomLayerTypes["BASE_P3_TF"] = "BASE_P3_TF";
    BottomLayerTypes["BASE_STICK_TSA"] = "BASE_STICK_TSA";
    BottomLayerTypes["BASE_V3_TT"] = "BASE_V3_TT";
    BottomLayerTypes["BASE_P3_SBS_TF_ATELIA"] = "BASE_P3_SBS_TF_ATELIA";
    BottomLayerTypes["BASE_P3_TF_ATELIA"] = "BASE_P3_TF_ATELIA";
    // NL
    BottomLayerTypes["BASE_460P60"] = "BASE_460P60";
    BottomLayerTypes["BASE_360P60"] = "BASE_360P60";
    BottomLayerTypes["BASE_460P60_ATELIA"] = "BASE_460P60_ATELIA";
    BottomLayerTypes["BASE_360P60_ATELIA"] = "BASE_360P60_ATELIA";
    BottomLayerTypes["BASE_P14"] = "BASE_P14";
    // DE
    BottomLayerTypes["BASE_STICK_FSA"] = "BASE_STICK_FSA";
    BottomLayerTypes["QUADRA_FSA_2_8"] = "QUADRA_FSA_2_8";
})(BottomLayerTypes = exports.BottomLayerTypes || (exports.BottomLayerTypes = {}));
var TopLayerTypes;
(function (TopLayerTypes) {
    // BE
    TopLayerTypes["CARRARA"] = "CARRARA";
    TopLayerTypes["CARBON_250"] = "CARBON_250";
    TopLayerTypes["CARBON"] = "CARBON";
    TopLayerTypes["PANTERA"] = "PANTERA";
    TopLayerTypes["IKO_ROOFGARDEN_PANTERA"] = "IKO_ROOFGARDEN_PANTERA";
    TopLayerTypes["IKO_ROOFGARDEN_4_APP_ADF"] = "IKO_ROOFGARDEN_4_APP_ADF";
    TopLayerTypes["POWERGUM_470K14_ATELIA"] = "POWERGUM_470K14_ATELIA";
    TopLayerTypes["POWERGUM_4AWF_ATELIA"] = "POWERGUM_4AWF_ATELIA";
    TopLayerTypes["CARRARA_TECNO_SN"] = "CARRARA_TECNO_SN";
    TopLayerTypes["SUMMA"] = "SUMMA";
    TopLayerTypes["POWERGUM_4_ADF"] = "POWERGUM_4_ADF";
    TopLayerTypes["POWERFLEX_4_ADF"] = "POWERFLEX_4_ADF";
    TopLayerTypes["SECURA"] = "SECURA";
    TopLayerTypes["TANETECH"] = "TANETECH";
    TopLayerTypes["METATECH"] = "METATECH";
    TopLayerTypes["HYBRITECH"] = "HYBRITECH";
    // NL
    TopLayerTypes["CARBON_HI_SPEED"] = "CARBON_HI_SPEED";
    TopLayerTypes["IKO_ROOFGARDEN_470K14"] = "IKO_ROOFGARDEN_470K14";
    TopLayerTypes["POWERGUM_470K14"] = "POWERGUM_470K14";
    TopLayerTypes["POWERGUM_470K24_DGRIJS"] = "POWERGUM_470K24_DGRIJS";
    TopLayerTypes["POWERGUM_470K24_WIT"] = "POWERGUM_470K24_WIT";
    // DE
    TopLayerTypes["POWERFLEX_5_ARF"] = "POWERFLEX_5_ARF";
    TopLayerTypes["IKO_ROOFGARDEN_SBS"] = "IKO_ROOFGARDEN_SBS";
    TopLayerTypes["IKO_ROOFGARDEN_ALPA_GOLD"] = "IKO_ROOFGARDEN_ALPA_GOLD";
    TopLayerTypes["IKO_ROOFGARDEN_PANTERA_5"] = "IKO_ROOFGARDEN_PANTERA_5";
    TopLayerTypes["CARBON_250_5"] = "CARBON_250_5";
    TopLayerTypes["CARRARA_5"] = "CARRARA_5";
    TopLayerTypes["SUMMA_5"] = "SUMMA_5";
})(TopLayerTypes = exports.TopLayerTypes || (exports.TopLayerTypes = {}));
var ExtendedTopLayerTypes;
(function (ExtendedTopLayerTypes) {
    ExtendedTopLayerTypes["EXT_BITUMINOUS_MEMBRANE"] = "EXT_BITUMINOUS_MEMBRANE";
    ExtendedTopLayerTypes["EXT_PVC"] = "EXT_PVC";
    ExtendedTopLayerTypes["EXT_EPDM"] = "EXT_EPDM";
})(ExtendedTopLayerTypes = exports.ExtendedTopLayerTypes || (exports.ExtendedTopLayerTypes = {}));
var SecuringMethodTypes;
(function (SecuringMethodTypes) {
    SecuringMethodTypes["WELDING"] = "Welding";
    SecuringMethodTypes["LIQUID"] = "Liquid";
    SecuringMethodTypes["SELF_ADHERING"] = "Self adhering";
    SecuringMethodTypes["MECHANICAL"] = "Mechanical";
    SecuringMethodTypes["MECHANICAL_BALLAST"] = "Mechanical ballast";
    SecuringMethodTypes["LOOSE"] = "Loose";
    SecuringMethodTypes["GLUE"] = "Glue";
    SecuringMethodTypes["LOOSE_BALLAST"] = "Loose ballast";
    SecuringMethodTypes["WARM_BITUMEN"] = "Warm bitumen";
})(SecuringMethodTypes = exports.SecuringMethodTypes || (exports.SecuringMethodTypes = {}));
var RoofDetails;
(function (RoofDetails) {
    RoofDetails["roofSurface"] = "roofSurface";
    RoofDetails["roofUpstand"] = "roofUpstand";
    RoofDetails["drainagePipe"] = "drainagePipe";
    RoofDetails["dome"] = "dome";
    RoofDetails["angleConnection"] = "angleConnection";
    RoofDetails["settlementJoint"] = "settlementJoint";
    RoofDetails["verticalWall"] = "verticalWall";
})(RoofDetails = exports.RoofDetails || (exports.RoofDetails = {}));
var FileType;
(function (FileType) {
    FileType["url"] = "url";
    FileType["document"] = "document";
})(FileType = exports.FileType || (exports.FileType = {}));
// CAVITY WALL
var WallTypeTypes;
(function (WallTypeTypes) {
    WallTypeTypes["CONCRETE_WALL"] = "Concrete wall";
    WallTypeTypes["LIME_SAND_WALL"] = "Lime sand wall";
    WallTypeTypes["HOLLOW_STONE_WALL"] = "Hollow stone wall";
    WallTypeTypes["CROSSWOOD_WALL"] = "Crosswood layer CLT";
})(WallTypeTypes = exports.WallTypeTypes || (exports.WallTypeTypes = {}));
var ExtendedWallTypeTypes;
(function (ExtendedWallTypeTypes) {
    // Human readable names
    ExtendedWallTypeTypes["EXT_HOLLOW_STONE_WALL_1100_GLUED"] = "Hollow stone wall 1100 glued";
    ExtendedWallTypeTypes["EXT_HOLLOW_STONE_WALL_1100_BRICKED"] = "Hollow stone wall 1100 bricked";
    ExtendedWallTypeTypes["EXT_HOLLOW_STONE_WALL_1400_GLUED"] = "Hollow stone wall 1400 glued";
    ExtendedWallTypeTypes["EXT_HOLLOW_STONE_WALL_1400_BRICKED"] = "Hollow stone wall 1400 bricked";
    ExtendedWallTypeTypes["EXT_CONCRETE_WALL_2400"] = "Concrete wall 2400";
    ExtendedWallTypeTypes["EXT_CONCRETE_WALL_400"] = "Concrete wall 400";
    ExtendedWallTypeTypes["EXT_CONCRETE_WALL_500"] = "Concrete wall 500";
    ExtendedWallTypeTypes["EXT_CONCRETE_WALL_600"] = "Concrete wall 600";
    ExtendedWallTypeTypes["EXT_CONCRETE_WALL_700"] = "Concrete wall 700";
    ExtendedWallTypeTypes["EXT_CONCRETE_WALL_800"] = "Concrete wall 800";
    ExtendedWallTypeTypes["EXT_LIME_SAND_WALL_1850"] = "Lime sand wall 1850";
    ExtendedWallTypeTypes["EXT_CROSSWOOD_WALL"] = "Crosswood wall";
})(ExtendedWallTypeTypes = exports.ExtendedWallTypeTypes || (exports.ExtendedWallTypeTypes = {}));
var WallInsulationTypes;
(function (WallInsulationTypes) {
    WallInsulationTypes["ALU_50"] = "ALU50";
    WallInsulationTypes["ALU_TG"] = "ALU TG";
})(WallInsulationTypes = exports.WallInsulationTypes || (exports.WallInsulationTypes = {}));
var OuterCavityLeafTypes;
(function (OuterCavityLeafTypes) {
    OuterCavityLeafTypes["FACADE_MASONRY"] = "Facade masonry";
})(OuterCavityLeafTypes = exports.OuterCavityLeafTypes || (exports.OuterCavityLeafTypes = {}));
var ExtendedOuterCavityLeafTypes;
(function (ExtendedOuterCavityLeafTypes) {
    // Human readable names
    ExtendedOuterCavityLeafTypes["EXT_HOLLOW_STONE_WALL_1600_GLUED"] = "Hollow stone wall 1600 glued";
    ExtendedOuterCavityLeafTypes["EXT_HOLLOW_STONE_WALL_1600_BRICKED"] = "Hollow stone wall 1600 bricked";
    ExtendedOuterCavityLeafTypes["EXT_HOLLOW_STONE_WALL_1700_GLUED"] = "Hollow stone wall 1700 glued";
    ExtendedOuterCavityLeafTypes["EXT_HOLLOW_STONE_WALL_1700_BRICKED"] = "Hollow stone wall 1700 bricked";
    ExtendedOuterCavityLeafTypes["EXT_HOLLOW_STONE_WALL_1800_GLUED"] = "Hollow stone wall 1800 glued";
    ExtendedOuterCavityLeafTypes["EXT_HOLLOW_STONE_WALL_1800_BRICKED"] = "Hollow stone wall 1800 bricked";
    ExtendedOuterCavityLeafTypes["EXT_HOLLOW_STONE_WALL_1900_GLUED"] = "Hollow stone wall 1900 glued";
    ExtendedOuterCavityLeafTypes["EXT_HOLLOW_STONE_WALL_1900_BRICKED"] = "Hollow stone wall 1900 bricked";
    ExtendedOuterCavityLeafTypes["EXT_HOLLOW_STONE_WALL_2000_GLUED"] = "Hollow stone wall 2000 glued";
    ExtendedOuterCavityLeafTypes["EXT_HOLLOW_STONE_WALL_2000_BRICKED"] = "Hollow stone wall 2000 bricked";
    ExtendedOuterCavityLeafTypes["EXT_CONCRETE_WALL_2100"] = "Concrete wall 2100";
    ExtendedOuterCavityLeafTypes["EXT_LIME_SAND_WALL_1850_LEAF"] = "Lime sand wall 1850";
})(ExtendedOuterCavityLeafTypes = exports.ExtendedOuterCavityLeafTypes || (exports.ExtendedOuterCavityLeafTypes = {}));
var VentilationTypes;
(function (VentilationTypes) {
    VentilationTypes["NO_VENTILATION"] = "No ventilation";
    VentilationTypes["WEAK_VENTILATION"] = "Weak ventilation";
    VentilationTypes["STRONG_VENTILATION"] = "Strong ventilation";
})(VentilationTypes = exports.VentilationTypes || (exports.VentilationTypes = {}));
exports.cavityWallMaterialMap = {
    [WallTypeTypes.CONCRETE_WALL]: MaterialIDs.CONCRETE_WALL,
    [WallTypeTypes.LIME_SAND_WALL]: MaterialIDs.LIME_SAND_WALL,
    [WallTypeTypes.HOLLOW_STONE_WALL]: MaterialIDs.HOLLOW_STONE_WALL,
    [WallTypeTypes.CROSSWOOD_WALL]: MaterialIDs.CROSSWOOD_WALL,
    [WallInsulationTypes.ALU_TG]: MaterialIDs.ENERTHERM_ALU_TG,
    [WallInsulationTypes.ALU_50]: MaterialIDs.ENERTHERM_ALU_50,
    [OuterCavityLeafTypes.FACADE_MASONRY]: MaterialIDs.FACADE_MASONRY,
    [VentilationTypes.NO_VENTILATION]: MaterialIDs.NO_VENTILATION,
    [VentilationTypes.WEAK_VENTILATION]: MaterialIDs.WEAK_VENTILATION,
    [VentilationTypes.STRONG_VENTILATION]: MaterialIDs.STRONG_VENTILATION,
};
var CavityWallLayers;
(function (CavityWallLayers) {
    CavityWallLayers["wallInsulation"] = "wallInsulation";
})(CavityWallLayers = exports.CavityWallLayers || (exports.CavityWallLayers = {}));
// SLOPED ROOF
var SlopedRoofTypeTypes;
(function (SlopedRoofTypeTypes) {
    // Human readable names
    SlopedRoofTypeTypes["TILED_ROOF"] = "Tiled roof";
    SlopedRoofTypeTypes["SLATED_ROOF"] = "Slated roof";
})(SlopedRoofTypeTypes = exports.SlopedRoofTypeTypes || (exports.SlopedRoofTypeTypes = {}));
var SlopedRoofInsulationTypeTypes;
(function (SlopedRoofInsulationTypeTypes) {
    // Human readable names
    SlopedRoofInsulationTypeTypes["ALU_NF"] = "ALU NF";
    SlopedRoofInsulationTypeTypes["ALU_NF_PRO"] = "ALU NF PRO";
})(SlopedRoofInsulationTypeTypes = exports.SlopedRoofInsulationTypeTypes || (exports.SlopedRoofInsulationTypeTypes = {}));
exports.slopedRoofMaterialMap = {
    [SlopedRoofTypeTypes.TILED_ROOF]: MaterialIDs.TILED_ROOF,
    [SlopedRoofTypeTypes.SLATED_ROOF]: MaterialIDs.SLATED_ROOF,
    [SlopedRoofInsulationTypeTypes.ALU_NF]: MaterialIDs.ENERTHERM_ALU_NF,
    [SlopedRoofInsulationTypeTypes.ALU_NF_PRO]: MaterialIDs.ENERTHERM_ALU_NF_PRO,
};
var SlopedRoofLayers;
(function (SlopedRoofLayers) {
    SlopedRoofLayers["roofInsulation"] = "roofInsulation";
})(SlopedRoofLayers = exports.SlopedRoofLayers || (exports.SlopedRoofLayers = {}));
// VENTILATED WALL
var VentilatedWallWallTypeTypes;
(function (VentilatedWallWallTypeTypes) {
    // Human readable names
    VentilatedWallWallTypeTypes["CONCRETE_WALL"] = "Concrete wall";
    VentilatedWallWallTypeTypes["LIME_SAND_WALL"] = "Lime sand wall";
    VentilatedWallWallTypeTypes["HOLLOW_STONE_WALL"] = "Hollow stone wall";
    VentilatedWallWallTypeTypes["CROSSWOOD_WALL"] = "Crosswood wall";
})(VentilatedWallWallTypeTypes = exports.VentilatedWallWallTypeTypes || (exports.VentilatedWallWallTypeTypes = {}));
var ExtendedVentilatedWallWallTypeTypes;
(function (ExtendedVentilatedWallWallTypeTypes) {
    // Human readable names
    ExtendedVentilatedWallWallTypeTypes["EXT_HOLLOW_STONE_WALL_1100_GLUED"] = "Hollow stone wall 1100 glued";
    ExtendedVentilatedWallWallTypeTypes["EXT_HOLLOW_STONE_WALL_1100_BRICKED"] = "Hollow stone wall 1100 bricked";
    ExtendedVentilatedWallWallTypeTypes["EXT_HOLLOW_STONE_WALL_1400_GLUED"] = "Hollow stone wall 1400 glued";
    ExtendedVentilatedWallWallTypeTypes["EXT_HOLLOW_STONE_WALL_1400_BRICKED"] = "Hollow stone wall 1400 bricked";
    ExtendedVentilatedWallWallTypeTypes["EXT_CONCRETE_WALL_2400"] = "Concrete wall 2400";
    ExtendedVentilatedWallWallTypeTypes["EXT_CONCRETE_WALL_400"] = "Concrete wall 400";
    ExtendedVentilatedWallWallTypeTypes["EXT_CONCRETE_WALL_500"] = "Concrete wall 500";
    ExtendedVentilatedWallWallTypeTypes["EXT_CONCRETE_WALL_600"] = "Concrete wall 600";
    ExtendedVentilatedWallWallTypeTypes["EXT_CONCRETE_WALL_700"] = "Concrete wall 700";
    ExtendedVentilatedWallWallTypeTypes["EXT_CONCRETE_WALL_800"] = "Concrete wall 800";
    ExtendedVentilatedWallWallTypeTypes["EXT_LIME_SAND_WALL_1850"] = "Lime sand wall 1850";
    ExtendedVentilatedWallWallTypeTypes["EXT_CROSSWOOD_WALL"] = "Crosswood wall";
})(ExtendedVentilatedWallWallTypeTypes = exports.ExtendedVentilatedWallWallTypeTypes || (exports.ExtendedVentilatedWallWallTypeTypes = {}));
var VentilatedWallFacadeCladdingTypes;
(function (VentilatedWallFacadeCladdingTypes) {
    // Human readable names
    VentilatedWallFacadeCladdingTypes["ETERNIT_CEDRAL"] = "Cedral";
    VentilatedWallFacadeCladdingTypes["ETERNIT_EQUITONE"] = "Equitone";
    VentilatedWallFacadeCladdingTypes["TRESPA_CLADDING"] = "Trespa";
    VentilatedWallFacadeCladdingTypes["ROCKPANEL_CLADDING"] = "Rockpanel";
    VentilatedWallFacadeCladdingTypes["SWISS_PEARL_CLADDING"] = "Swiss Pearl";
    VentilatedWallFacadeCladdingTypes["FIBERPLAST_CLADDING"] = "Fiberplast";
    VentilatedWallFacadeCladdingTypes["KERALIT_CLADDING"] = "Keralit";
    VentilatedWallFacadeCladdingTypes["FIBER_CEMENT_CLADDING"] = "Fiber cement";
    VentilatedWallFacadeCladdingTypes["WOOD_CLADDING"] = "Wood";
    VentilatedWallFacadeCladdingTypes["METAL_CLADDING"] = "Metal";
    VentilatedWallFacadeCladdingTypes["COMPOSITE_CLADDING"] = "Composite";
    VentilatedWallFacadeCladdingTypes["OTHER_CLADDING"] = "Other";
})(VentilatedWallFacadeCladdingTypes = exports.VentilatedWallFacadeCladdingTypes || (exports.VentilatedWallFacadeCladdingTypes = {}));
var VentilatedWallInsulationTypes;
(function (VentilatedWallInsulationTypes) {
    // Human readable names
    VentilatedWallInsulationTypes["ENERTHERM_ALU_PURE"] = "ALU PURE";
})(VentilatedWallInsulationTypes = exports.VentilatedWallInsulationTypes || (exports.VentilatedWallInsulationTypes = {}));
var VentilatedWallVaporShieldTypes;
(function (VentilatedWallVaporShieldTypes) {
    // Human readable names
    VentilatedWallVaporShieldTypes["WATERPROOF_VAPOR_SHIELD"] = "Waterproof vapor shield";
    VentilatedWallVaporShieldTypes["NO_WATERPROOF_VAPOR_SHIELD"] = "No waterproof vapor shield";
    VentilatedWallVaporShieldTypes["CEMENT_WINDBREAKER"] = "Cement windbreaker";
})(VentilatedWallVaporShieldTypes = exports.VentilatedWallVaporShieldTypes || (exports.VentilatedWallVaporShieldTypes = {}));
exports.ventilatedWallMaterialMap = {
    [VentilatedWallWallTypeTypes.CONCRETE_WALL]: MaterialIDs.CONCRETE_WALL,
    [VentilatedWallWallTypeTypes.LIME_SAND_WALL]: MaterialIDs.LIME_SAND_WALL,
    [VentilatedWallWallTypeTypes.HOLLOW_STONE_WALL]: MaterialIDs.HOLLOW_STONE_WALL,
    [VentilatedWallWallTypeTypes.CROSSWOOD_WALL]: MaterialIDs.CROSSWOOD_WALL,
    [VentilatedWallFacadeCladdingTypes.ETERNIT_CEDRAL]: MaterialIDs.ETERNIT_CEDRAL,
    [VentilatedWallFacadeCladdingTypes.ETERNIT_EQUITONE]: MaterialIDs.ETERNIT_EQUITONE,
    [VentilatedWallFacadeCladdingTypes.TRESPA_CLADDING]: MaterialIDs.TRESPA_CLADDING,
    [VentilatedWallFacadeCladdingTypes.ROCKPANEL_CLADDING]: MaterialIDs.ROCKPANEL_CLADDING,
    [VentilatedWallFacadeCladdingTypes.SWISS_PEARL_CLADDING]: MaterialIDs.SWISS_PEARL_CLADDING,
    [VentilatedWallFacadeCladdingTypes.FIBERPLAST_CLADDING]: MaterialIDs.FIBERPLAST_CLADDING,
    [VentilatedWallFacadeCladdingTypes.KERALIT_CLADDING]: MaterialIDs.KERALIT_CLADDING,
    [VentilatedWallFacadeCladdingTypes.FIBER_CEMENT_CLADDING]: MaterialIDs.FIBER_CEMENT_CLADDING,
    [VentilatedWallFacadeCladdingTypes.WOOD_CLADDING]: MaterialIDs.WOOD_CLADDING,
    [VentilatedWallFacadeCladdingTypes.METAL_CLADDING]: MaterialIDs.METAL_CLADDING,
    [VentilatedWallFacadeCladdingTypes.COMPOSITE_CLADDING]: MaterialIDs.COMPOSITE_CLADDING,
    [VentilatedWallFacadeCladdingTypes.OTHER_CLADDING]: MaterialIDs.OTHER_CLADDING,
    [VentilatedWallInsulationTypes.ENERTHERM_ALU_PURE]: MaterialIDs.ENERTHERM_ALU_PURE,
    [VentilatedWallVaporShieldTypes.WATERPROOF_VAPOR_SHIELD]: MaterialIDs.WATERPROOF_VAPOR_SHIELD,
    [VentilatedWallVaporShieldTypes.NO_WATERPROOF_VAPOR_SHIELD]: MaterialIDs.NO_WATERPROOF_VAPOR_SHIELD,
    [VentilatedWallVaporShieldTypes.CEMENT_WINDBREAKER]: MaterialIDs.CEMENT_WINDBREAKER,
};
var VentilatedWallLayers;
(function (VentilatedWallLayers) {
    VentilatedWallLayers["wallInsulation"] = "wallInsulation";
})(VentilatedWallLayers = exports.VentilatedWallLayers || (exports.VentilatedWallLayers = {}));
// FLOOR
var FloorHeatingTypes;
(function (FloorHeatingTypes) {
    // Human readable names
    FloorHeatingTypes["FLOOR_HEATING"] = "Floor heating";
    FloorHeatingTypes["NO_FLOOR_HEATING"] = "No floor heating";
})(FloorHeatingTypes = exports.FloorHeatingTypes || (exports.FloorHeatingTypes = {}));
var FloorInsulationTypes;
(function (FloorInsulationTypes) {
    // Human readable names
    FloorInsulationTypes["ENERTHERM_ALU"] = "ALU";
    FloorInsulationTypes["ENERTHERM_KR_ALU"] = "KR";
})(FloorInsulationTypes = exports.FloorInsulationTypes || (exports.FloorInsulationTypes = {}));
var FloorScreedTypes;
(function (FloorScreedTypes) {
    // Human readable names
    FloorScreedTypes["ANHYDRITE_FLOOR"] = "Anhydrite";
    FloorScreedTypes["CHAPE_FLOOR"] = "Chape";
})(FloorScreedTypes = exports.FloorScreedTypes || (exports.FloorScreedTypes = {}));
var FloorBearingTypes;
(function (FloorBearingTypes) {
    // Human readable names
    FloorBearingTypes["REINFORCED_CONCRETE_FLOOR"] = "Reinforced concrete floor";
    FloorBearingTypes["RIBBED_CASSETTE_FLOOR"] = "Ribbed cassette floor";
    FloorBearingTypes["HOLLOW_CORE_SLAB_FLOOR"] = "hollow core slab floor";
    FloorBearingTypes["COMBINATION_FLOOR"] = "Combination floor";
})(FloorBearingTypes = exports.FloorBearingTypes || (exports.FloorBearingTypes = {}));
exports.FloorMaterialMap = {
    [FloorHeatingTypes.FLOOR_HEATING]: MaterialIDs.FLOOR_HEATING,
    [FloorHeatingTypes.NO_FLOOR_HEATING]: MaterialIDs.NO_FLOOR_HEATING,
    [FloorInsulationTypes.ENERTHERM_ALU]: MaterialIDs.ENERTHERM_ALU,
    [FloorInsulationTypes.ENERTHERM_KR_ALU]: MaterialIDs.ENERTHERM_KR_ALU,
    [FloorScreedTypes.ANHYDRITE_FLOOR]: MaterialIDs.ANHYDRITE_FLOOR,
    [FloorScreedTypes.CHAPE_FLOOR]: MaterialIDs.CHAPE_FLOOR,
};
var FloorLayers;
(function (FloorLayers) {
    FloorLayers["floorInsulation"] = "floorInsulation";
})(FloorLayers = exports.FloorLayers || (exports.FloorLayers = {}));
// SOL
var SolInsulationTypes;
(function (SolInsulationTypes) {
    // Human readable names
    SolInsulationTypes["ENERTHERM_CHAPE_TG"] = "CHAPE TG";
    SolInsulationTypes["ENERTHERM_KR_ALU_SOUSDALLAGE"] = "KR";
    SolInsulationTypes["ENERTHERM_KR_ALU_DALLEPORTEE"] = "KR ALU";
})(SolInsulationTypes = exports.SolInsulationTypes || (exports.SolInsulationTypes = {}));
var SolNoiseLevelTypes;
(function (SolNoiseLevelTypes) {
    // Human readable names
    SolNoiseLevelTypes["DB_18"] = "<= 18 dB";
    SolNoiseLevelTypes["DB_19"] = "<= 19 dB";
    SolNoiseLevelTypes["DB_20"] = "<= 20 dB";
    SolNoiseLevelTypes["DB_22"] = "<= 22 dB";
    SolNoiseLevelTypes["DB_23"] = "<= 23 dB";
    SolNoiseLevelTypes["AUCUNE"] = "AUCUNE";
})(SolNoiseLevelTypes = exports.SolNoiseLevelTypes || (exports.SolNoiseLevelTypes = {}));
var SolAcousticLayerTypes;
(function (SolAcousticLayerTypes) {
    // Human readable names
    SolAcousticLayerTypes["TRAMICHAPE_ECO_PRO"] = "TRAMICHAPE ECO PRO";
    SolAcousticLayerTypes["ASSOURCHAPE_20"] = "ASSOURCHAPE 20";
    SolAcousticLayerTypes["AUCUNE"] = "AUCUNE";
})(SolAcousticLayerTypes = exports.SolAcousticLayerTypes || (exports.SolAcousticLayerTypes = {}));
var SolConformityTypes;
(function (SolConformityTypes) {
    // Human readable names
    SolConformityTypes["P1_1_1"] = "DTU 13.3 P1-1-1";
    SolConformityTypes["P1_1_2"] = "DTU 13.3 P1-1-2";
})(SolConformityTypes = exports.SolConformityTypes || (exports.SolConformityTypes = {}));
var SolBuildingTypeTypes;
(function (SolBuildingTypeTypes) {
    // Human readable names
    SolBuildingTypeTypes["OUI"] = "OUI";
    SolBuildingTypeTypes["NON"] = "NON";
})(SolBuildingTypeTypes = exports.SolBuildingTypeTypes || (exports.SolBuildingTypeTypes = {}));
exports.SolMaterialMap = {
    [SolInsulationTypes.ENERTHERM_CHAPE_TG]: MaterialIDs.ENERTHERM_CHAPE_TG,
    [SolInsulationTypes.ENERTHERM_KR_ALU_SOUSDALLAGE]: MaterialIDs.ENERTHERM_KR_ALU_SOUSDALLAGE,
    [SolInsulationTypes.ENERTHERM_KR_ALU_DALLEPORTEE]: MaterialIDs.ENERTHERM_KR_ALU_DALLEPORTEE,
};
var SolLayers;
(function (SolLayers) {
    SolLayers["solInsulation"] = "solInsulation";
})(SolLayers = exports.SolLayers || (exports.SolLayers = {}));
