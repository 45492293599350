export function useExtendedThermalCalculation() {
  // Material R values
  function calculateMaterialThermalPerformance(
    material: any,
    thicknessThreshold?: number
  ) {
    const { lambdaThicknessThresholds, lambdas, thickness } = material
    const isVariable =
      lambdaThicknessThresholds &&
      lambdaThicknessThresholds.length > 0 &&
      lambdas.length > 1
    let lambda: number | null = null
    let RValue: number | null = null

    if (!isVariable) {
      lambda = lambdas[0]
    } else {
      lambdaThicknessThresholds!.forEach((threshold: number, index: number) => {
        if (thickness >= threshold) lambda = lambdas[index + 1]
        else lambda = lambdas[index]
      })
    }

    if (thickness && lambda) {
      RValue = thickness / 1000 / lambda
    }

    if (thicknessThreshold && thickness < thicknessThreshold) {
      RValue = 0
    }

    return {
      lambda,
      thickness,
      RValue,
      valid: RValue !== null,
    }
  }

  // Ut and Rt
  function calculatePreliminaryThermalPerformance(
    performanceLayers: Array<any>,
    RSI: number,
    RSE: number
  ) {
    const sumOfRValues = performanceLayers.reduce(
      (acc: number, layer: any) => acc + layer.RValue!,
      0
    )
    const RTValue = RSI + sumOfRValues + RSE
    const UTValue = 1 / RTValue

    return [RTValue, UTValue]
  }

  // ΔU - convection
  function calculateConvectionCorrection(correctionData: any) {
    let correctionUValue = 0

    if (correctionData.insulationR != null && correctionData.RT != null) {
      correctionUValue =
        correctionData.convectionU *
        Math.pow(correctionData.insulationR / correctionData.RT, 2)
    }

    return correctionUValue
  }

  // ΔU - fixation
  function calculateFixationCorrection(correctionData: any) {
    const {
      fixationLambda: Lf,
      fixationDiameter: Df,
      fixationCount: Nf,
      // fixationDtule: dtule,
      fixationDfa: dfa,
      insulationD: Diso,
      insulationR: Riso,
      RT,
    } = correctionData
    let correctionUValue = 0

    if (
      Lf != null &&
      Df != null &&
      Nf != null &&
      Diso != null &&
      Riso != null &&
      RT != null
    ) {
      const firstEquationPart = 0.8 * (dfa / Diso)
      const secondEquationPart =
        Lf * Nf * Math.PI * Math.pow((0.5 * Df) / 1000, 2)
      const thirdEquationPart = Diso * 0.001
      const fourthEquationPart = Math.pow(Riso / RT, 2)

      correctionUValue =
        ((firstEquationPart * secondEquationPart) / thirdEquationPart) *
        fourthEquationPart
    }

    return isNaN(correctionUValue) ? 0 : correctionUValue
  }

  // ΔU
  function calculateCorrection(
    convectionUValue: number,
    fixationUValue: number,
    UTValue: number
  ) {
    let deltaUValue = 0
    let sumOfCorrectionValues = convectionUValue + fixationUValue
    if (sumOfCorrectionValues > UTValue * 0.03) {
      deltaUValue = sumOfCorrectionValues
    }
    return deltaUValue
  }

  // Uc and Rc
  function calculateTotalsThermalPerformance(
    UTValue: number,
    deltaUValue: number,
    RSI: number,
    RSE: number,
    Fprac: number = 1.0
  ) {
    const UCValue = UTValue * Fprac + deltaUValue
    const RCValue = 1 / UCValue - RSI - RSE
    return [RCValue, UCValue]
  }

  function formatCalc(value: number | undefined, decimalPlaces: number = 3) {
    if (value === undefined || value === null) return undefined
    return Number(value).toFixed(decimalPlaces)
  }

  return {
    calculateMaterialThermalPerformance,
    calculatePreliminaryThermalPerformance,
    calculateConvectionCorrection,
    calculateFixationCorrection,
    calculateCorrection,
    calculateTotalsThermalPerformance,
    formatCalc,
  }
}
