import {
    EXTENDED_CAVITY_WALL_R_VALUE_RSI,
    EXTENDED_CAVITY_WALL_R_VALUE_RSE,
} from "@iko-design-center/shared";
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useCavityWall } from "../../../../store/cavity-wall/hooks";
import { useExtendedThermalCalculation } from "./extendedCalculation.hook";
import { parseMaterial } from "../../../../services/helpers.service";
import { ExtendedOuterCavityLeafTypes, WallTypeTypes, VentilationTypes, ExtendedWallTypeTypes } from "@iko-design-center/shared/lib/constants/constants";
import { getWallTypeTranslations } from "../translations";
import { useTranslation } from "react-i18next";

type State = any
type ThermalStateProviderProps = { children: ReactNode }
const ThermalStateContext = createContext<State | undefined>(undefined)

const getVentialtionFixedThermalPerformance = (ventilationType: VentilationTypes) => {
    const data: {
        lambda: number | null; thickness: any; RValue: number | null; valid: boolean;
    } = {
        RValue: 0.00,
        lambda: 0.01,
        thickness: 20,
        valid: true,
    }
    switch (ventilationType) {
        case VentilationTypes.NO_VENTILATION:
            data.RValue = 0.570
            return data;
        case VentilationTypes.WEAK_VENTILATION:
            data.RValue = 0.400
            return data;
        case VentilationTypes.STRONG_VENTILATION:
            data.RValue = 0.000
            return data;
    }
}

function ThermalStateProvider({ children }: ThermalStateProviderProps) {
    const { t } = useTranslation() as any
    const { configurationState } = useCavityWall()
    const {
        calculateMaterialThermalPerformance,
        calculatePreliminaryThermalPerformance,
        calculateConvectionCorrection,
        calculateFixationCorrection,
        calculateCorrection,
        calculateTotalsThermalPerformance,
    } = useExtendedThermalCalculation()

    const initialState: any = {
        wallType: null,
        wallInsulation: {
            type: configurationState.wallInsulation.type,
            material: configurationState.wallInsulation.material,
        },
        wallCavity: {
            type: VentilationTypes.WEAK_VENTILATION,
            material: parseMaterial('WEAK_VENTILATION'),
        },
        outerCavityLeaf: {
            type: ExtendedOuterCavityLeafTypes.EXT_HOLLOW_STONE_WALL_1600_BRICKED,
            material: parseMaterial('EXT_HOLLOW_STONE_WALL_1600_BRICKED'),
        }
    }

    switch (configurationState.wallType.type) {
        case WallTypeTypes.CONCRETE_WALL:
            initialState.wallType = {
                type: ExtendedWallTypeTypes.EXT_CONCRETE_WALL_2400, material: parseMaterial('EXT_CONCRETE_WALL_2400'), options:
                    [
                        { value: "EXT_CONCRETE_WALL_2400", label: getWallTypeTranslations(t, ExtendedWallTypeTypes.EXT_CONCRETE_WALL_2400) },
                        { value: "EXT_CONCRETE_WALL_400", label: getWallTypeTranslations(t, ExtendedWallTypeTypes.EXT_CONCRETE_WALL_400) },
                        { value: "EXT_CONCRETE_WALL_500", label: getWallTypeTranslations(t, ExtendedWallTypeTypes.EXT_CONCRETE_WALL_500) },
                        { value: "EXT_CONCRETE_WALL_600", label: getWallTypeTranslations(t, ExtendedWallTypeTypes.EXT_CONCRETE_WALL_600) },
                        { value: "EXT_CONCRETE_WALL_700", label: getWallTypeTranslations(t, ExtendedWallTypeTypes.EXT_CONCRETE_WALL_700) },
                        { value: "EXT_CONCRETE_WALL_800", label: getWallTypeTranslations(t, ExtendedWallTypeTypes.EXT_CONCRETE_WALL_800) },
                    ]
            }
            break
        case WallTypeTypes.HOLLOW_STONE_WALL:
            initialState.wallType = {
                type: ExtendedWallTypeTypes.EXT_HOLLOW_STONE_WALL_1400_BRICKED, material: parseMaterial('EXT_HOLLOW_STONE_WALL_1400_BRICKED'), options:
                    [
                        { value: "EXT_HOLLOW_STONE_WALL_1100_GLUED", label: getWallTypeTranslations(t, ExtendedWallTypeTypes.EXT_HOLLOW_STONE_WALL_1100_GLUED) },
                        { value: "EXT_HOLLOW_STONE_WALL_1100_BRICKED", label: getWallTypeTranslations(t, ExtendedWallTypeTypes.EXT_HOLLOW_STONE_WALL_1100_BRICKED) },
                        { value: "EXT_HOLLOW_STONE_WALL_1400_GLUED", label: getWallTypeTranslations(t, ExtendedWallTypeTypes.EXT_HOLLOW_STONE_WALL_1400_GLUED) },
                        { value: "EXT_HOLLOW_STONE_WALL_1400_BRICKED", label: getWallTypeTranslations(t, ExtendedWallTypeTypes.EXT_HOLLOW_STONE_WALL_1400_BRICKED) },
                    ]
            }
            break
        case WallTypeTypes.LIME_SAND_WALL:
            initialState.wallType = {
                type: ExtendedWallTypeTypes.EXT_LIME_SAND_WALL_1850, material: parseMaterial('EXT_LIME_SAND_WALL_1850'), option:
                    [
                        { value: "EXT_LIME_SAND_WALL_1850", label: getWallTypeTranslations(t, ExtendedWallTypeTypes.EXT_LIME_SAND_WALL_1850) },
                    ]
            }
            break
        case WallTypeTypes.CROSSWOOD_WALL:
            initialState.wallType = {
                type: ExtendedWallTypeTypes.EXT_CROSSWOOD_WALL, material: parseMaterial('EXT_CROSSWOOD_WALL'), option:
                    [
                        { value: "EXT_CROSSWOOD_WALL", label: getWallTypeTranslations(t, ExtendedWallTypeTypes.EXT_CROSSWOOD_WALL) },
                    ]
            }
            break
    }

    const initialPerformance = {
        wallType: calculateMaterialThermalPerformance(initialState.wallType.material),
        wallInsulation: calculateMaterialThermalPerformance(initialState.wallInsulation.material),
        wallCavity: getVentialtionFixedThermalPerformance(initialState.wallCavity.type),
        outerCavityLeaf: calculateMaterialThermalPerformance(initialState.outerCavityLeaf.material),
        UTValue: null as number | null,
        RTValue: null as number | null,
        convectionUValue: null as number | null,
        fixationUValue: null as number | null,
        deltaUValue: null as number | null,
        UCValue: null as number | null,
        RCValue: null as number | null,
    }

    const initialCorrections = {
        convection: {
            type: "NO_CONVECTION",
            UValue: 0,
        },
        fixation: {
            type: "GALVANIZED_STEEL_FIXATION",
            lambda: 50,
            diameter: 6,
            count: 3
        },
    }

    const initialConstants = {
        RSI: { label: 'RSI', RValue: EXTENDED_CAVITY_WALL_R_VALUE_RSI },
        RSE: { label: 'RSE', RValue: EXTENDED_CAVITY_WALL_R_VALUE_RSE },
    }

    const [state, setState] = useState(initialState)
    const [performance, setPerformance] = useState(initialPerformance)
    const [corrections, setCorrections] = useState(initialCorrections)
    const [constants, setConstants] = useState(initialConstants)

    useEffect(() => {
        // Preliminary
        const wallTypeThermalPerformance = calculateMaterialThermalPerformance(state.wallType.material)
        const wallInsulationThermalPerformance = calculateMaterialThermalPerformance(state.wallInsulation.material)
        const wallCavityThermalPerformance = getVentialtionFixedThermalPerformance(state.wallCavity.type)
        const outerCavityLeafThermalPerformance = calculateMaterialThermalPerformance(state.outerCavityLeaf.material)

        const [RTValue, UTValue] = calculatePreliminaryThermalPerformance(
            [
                wallTypeThermalPerformance,
                wallInsulationThermalPerformance,
                wallCavityThermalPerformance,
                outerCavityLeafThermalPerformance
            ],
            initialConstants.RSI.RValue,
            initialConstants.RSE.RValue
        )
        // Corrections
        const convectionUValue = calculateConvectionCorrection({
            convectionU: corrections.convection.UValue,
            insulationR: wallInsulationThermalPerformance.RValue,
            RT: RTValue,
        })
        const fixationUValue = calculateFixationCorrection({
            fixationLambda: corrections.fixation.lambda,
            fixationDiameter: corrections.fixation.diameter,
            fixationCount: corrections.fixation.count,
            insulationD: wallInsulationThermalPerformance.thickness,
            insulationR: wallInsulationThermalPerformance.RValue,
            RT: RTValue,
        })
        const deltaUValue = calculateCorrection(convectionUValue, fixationUValue, UTValue)
        // Totals
        const [RCValue, UCValue] = calculateTotalsThermalPerformance(
            UTValue,
            deltaUValue,
            initialConstants.RSI.RValue,
            initialConstants.RSE.RValue
        )

        const newThermalPerformance = {
            ...performance,
            wallType: wallTypeThermalPerformance,
            wallInsulation: wallInsulationThermalPerformance,
            wallCavity: wallCavityThermalPerformance,
            outerCavityLeaf: outerCavityLeafThermalPerformance,
            RTValue,
            UTValue,
            convectionUValue,
            fixationUValue,
            deltaUValue,
            UCValue,
            RCValue
        }

        setPerformance(newThermalPerformance)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, corrections])

    return (
        <ThermalStateContext.Provider value={{
            state, setState,
            performance, setPerformance,
            corrections, setCorrections,
            constants, setConstants
        }}>
            {children}
        </ThermalStateContext.Provider>
    )
}

function useThermalState() {
    const context = useContext(ThermalStateContext)
    if (context === undefined) {
        throw new Error('useThermalState must be used within a ThermalStateProvider')
    }
    return context
}

export { ThermalStateProvider, useThermalState }
