import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "../../../services/api.service";
import { useFlatRoof } from "../../../store/flat-roof/hooks";
import { useModal } from "../../../store/modal/hooks";
import { styles } from "../../../styles";
import { DownloadIcon } from "../../common/Icons";
import { Form, Input, Label, Required, TextArea } from "../../common/Input";
import { P } from "../../common/Typography";
import { StandardModal } from "../../modal/StandardModal";
import { validationRegex } from "../../modal/validationRegex";
import styled from "styled-components";
import { BottomLayerTypes, FLAT_ROOF_SPECIFICATIONS, InsulationTypes, SecuringMethodTypes, TopLayerTypes, VaporShieldTypes } from "@iko-design-center/shared/lib/constants";
import { getEnumKey } from "../../../services/helpers.service";

const OptinRow = styled.div`
  width: 100%;
  display: flex;
`;
const PhoneRow = styled.div`
  width: 100%;
  display: flex;
`;
const PhoneContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
`;
const SpacerContainer = styled.div`
  width: 20px;
`;
const PostalCodeContainer = styled.div`
  flex: 1;
  width: ${(props) => props.theme.designWidth(120)}px;
  display: flex;
  flex-direction: column;
`;

export const DownloadMail = () => {
  const { t, i18n } = useTranslation();
  const { displayFlatRoofDownloadSuccessModal } = useModal();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [optin, setOptin] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [invalidPostalCode, setInvalidPostalCode] = useState(false);
  const [gdpr, setGdpr] = useState(false);
  const [invalidGdpr, setInvalidGdpr] = useState(false);
  const {
    flatRoofState,
    downloadState,
    thermalPerformance,
    configurationState,
    flatRoofScreenshots,
  } = useFlatRoof();
  const language = i18n.language;
  const country = i18n.language.split("_")[1]

  function handleEmailChange(e: ChangeEvent<HTMLInputElement>) {
    setInvalidEmail(false);
    setEmail(e.target.value);
  }

  function handleNameChange(e: ChangeEvent<HTMLInputElement>) {
    setInvalidName(false);
    setName(e.target.value);
  }

  function handlePhoneChange(e: ChangeEvent<HTMLInputElement>) {
    setInvalidPhone(false);
    setPhone(e.target.value);
  }

  function handlePostalCodeChange(e: ChangeEvent<HTMLInputElement>) {
    setInvalidPostalCode(false);
    setPostalCode(e.target.value);
  }

  function handleGdprChange(e: ChangeEvent<HTMLInputElement>) {
    setInvalidGdpr(false);
    setGdpr(e.target.checked);
  }

  async function validate(e: SyntheticEvent) {
    const validMail = validationRegex.email.test(String(email).toLowerCase());
    const validName = name !== "";
    const validPhone = validationRegex.phone.test(phone);
    const validPostalCode = postalCode !== "";
    const validGdpr = gdpr !== false;

    if (!validMail) {
      setInvalidEmail(true);
    }

    if (!validName) {
      setInvalidName(true);
    }

    if (!validPhone) {
      setInvalidPhone(true);
    }

    if (!validPostalCode) {
      setInvalidPostalCode(true);
    }

    if (!validGdpr) {
      setInvalidGdpr(true);
    }

    if (validName && validMail && validPhone && validPostalCode && validGdpr) {
    const target = e.currentTarget;
    target.classList.add("clicked");
    target.setAttribute("disabled", "disabled");

    // TODO: Implement loading state
    const {
      topLayer,
      bottomLayer,
      insulation,
      vaporShield,
      UCValue,
      RTValue,
      roofFloor,
    } = thermalPerformance;

    // Toplayer
    const topLayerState = configurationState.securingMethods.topLayer;
    let topLayerSpecification = null
    if (topLayerState) {
      const topLayerMethod = getEnumKey(
        configurationState.securingMethods.topLayer?.method,
        SecuringMethodTypes
      )
      const topLayerKey = `${country.toUpperCase()} / TOP_LAYER / ${topLayerState.type as TopLayerTypes} / ${(topLayerMethod as SecuringMethodTypes)}`
      topLayerSpecification = FLAT_ROOF_SPECIFICATIONS.find((spec) => spec.id === topLayerKey)
    }

    // Bottomlayer
    const bottomLayerState = configurationState.securingMethods.bottomLayer;
    let bottomLayerSpecification = null
    if (bottomLayerState) {
      const bottomLayerMethod = getEnumKey(
        configurationState.securingMethods.bottomLayer?.method,
        SecuringMethodTypes
      )
      const bottomLayerKey = `${country.toUpperCase()} / BOTTOM_LAYER / ${bottomLayerState.type as BottomLayerTypes} / ${(bottomLayerMethod as SecuringMethodTypes)}`
      bottomLayerSpecification = FLAT_ROOF_SPECIFICATIONS.find((spec) => spec.id === bottomLayerKey)
    }

    // Insulation
    const insulationState = configurationState.securingMethods.insulation;
    let insulationSpecification = null
    if (insulationState) {
      const insulationMethod = getEnumKey(
        configurationState.securingMethods.insulation?.method,
        SecuringMethodTypes
      )
      
      const insulationKey = `${country.toUpperCase()} / INSULATION / ${insulationState.type as InsulationTypes} / ${(insulationMethod as SecuringMethodTypes)}`
      insulationSpecification = FLAT_ROOF_SPECIFICATIONS.find((spec) => spec.id === insulationKey)
    }

    // Vapor Shield
    const vaporShieldState = configurationState.securingMethods.vaporShield;
    let vaporShieldSpecification = null
    if (vaporShieldState) {
      const vaporShieldMethod = getEnumKey(
        configurationState.securingMethods.vaporShield?.method,
        SecuringMethodTypes
      )
      const vaporShieldKey = `${country.toUpperCase()} / VAPOR_SHIELD / ${vaporShieldState.type as VaporShieldTypes} / ${(vaporShieldMethod as SecuringMethodTypes)}`
      vaporShieldSpecification = FLAT_ROOF_SPECIFICATIONS.find((spec) => spec.id === vaporShieldKey)
    }


    // Finishing
    const finishingState = configurationState.securingMethods.finishing;
    let finishingSpecification = null
    if (finishingState) {
      finishingSpecification = FLAT_ROOF_SPECIFICATIONS.find((spec) => spec.id === `${country.toUpperCase()} / FINISHING / BALLAST`)
    }

    const speficicationFiles = {
      topLayer: topLayerSpecification ? topLayerSpecification.translated_url : null,
      bottomLayer: bottomLayerSpecification ? bottomLayerSpecification.translated_url : null,
      insulation: insulationSpecification ? insulationSpecification.translated_url : null,
      vaporShield: vaporShieldSpecification ? vaporShieldSpecification.translated_url : null,
      finishing: finishingSpecification ? finishingSpecification.translated_url : null,
    }

    api
      .postDownload(
        {
          screenshots: flatRoofScreenshots,
          humanReadableName:
            configurationState.humanReadableName ?? undefined,
          overviewScreenshot: flatRoofState.overviewScreenshot!,
          specifications: speficicationFiles,
          thermalPerformance: {
            UCValue: UCValue!,
            RTValue: RTValue!,
            topLayer: {
              thickness: topLayer.thickness!,
              lambda: topLayer.lambda!,
              RValue: topLayer.RValue!,
            },
            bottomLayer: {
              thickness: bottomLayer.thickness!,
              lambda: bottomLayer.lambda!,
              RValue: bottomLayer.RValue!,
            },
            insulation: {
              thickness: insulation.thickness!,
              lambda: insulation.lambda!,
              RValue: insulation.RValue!,
            },
            vaporShield: {
              thickness: vaporShield.thickness!,
              lambda: vaporShield.lambda!,
              RValue: vaporShield.RValue!,
            },
            roofFloor: {
              thickness: roofFloor.thickness!,
              lambda: roofFloor.lambda!,
              RValue: roofFloor.RValue!,
            },
          },
          download: downloadState,
          securingMethods: {
            topLayer: configurationState.securingMethods.topLayer?.method,
            bottomLayer: configurationState.securingMethods.bottomLayer ? configurationState.securingMethods.bottomLayer.method : null,
            insulation: configurationState.securingMethods.insulation?.method,
            vaporShield: configurationState.securingMethods.vaporShield?.method,
          },
          user: {
            name,
            email,
            phone,
            postalCode,
            company,
            message,
            language,
            optin,
          },
          materials: {
            topLayerId: configurationState.roofStructure.topLayerMaterial!.id,
            bottomLayerId: configurationState.roofStructure
              .bottomLayerMaterial ? configurationState.roofStructure
                .bottomLayerMaterial!.id! : null,
            insulationId: configurationState.roofStructure.insulationMaterial!
              .id!,
            vaporShieldId: configurationState.roofStructure
              .vaporShieldMaterial!.id!,
            roofFloorId: configurationState.roofStructure.roofFloorMaterial!
              .id,
          },
        },
        "flat-roof"
      )
      .then((url) => {
        displayFlatRoofDownloadSuccessModal();
        if (typeof (window as any).lintrk !== "undefined") {
          (window as any).lintrk("track", { conversion_id: 5352082 });
        }
      })
      .catch((error) => {
        target.classList.remove("clicked");
        target.removeAttribute("disabled");
      });
    }
  }

  async function handleSubmit(e: SyntheticEvent) {
    e.preventDefault();

    await validate(e);
  }

  return (
    <StandardModal
      titleIcon={DownloadIcon}
      title={t("modal.downloadMail.title")}
      subtitle={t("modal.downloadMail.subtitle")}
      buttonText={t("modal.downloadMail.button")}
      handleSubmit={handleSubmit}
      classnames={"mail standard-modal"}
    >
      <Form onSubmit={handleSubmit}>
        <Label htmlFor="name">
          {t("modal.downloadMail.nameLabel")}
          <Required>*</Required>
        </Label>
        <Input
          invalid={invalidName}
          type="text"
          value={name}
          onChange={handleNameChange}
        />
        {invalidName && (
          <P fontSizePx={12} color={styles.colors.white65} lineHeightPx={20}>
            {t("modal.downloadMail.invalidName")}
          </P>
        )}
        <Label htmlFor="email">
          {t("modal.downloadMail.emailLabel")}
          <Required>*</Required>
        </Label>
        <Input
          invalid={invalidEmail}
          type="email"
          value={email}
          onChange={handleEmailChange}
          required
        />
        {invalidEmail && (
          <P fontSizePx={12} color={styles.colors.white65} lineHeightPx={20}>
            {t("modal.downloadMail.invalidEmail")}
          </P>
        )}
        <PhoneRow>
          <PhoneContainer>
            <Label htmlFor="phone">
              {t("modal.requestAdvice.phoneLabel")}
              <Required>*</Required>
            </Label>
            <Input
              invalid={invalidPhone}
              type="text"
              value={phone}
              onChange={handlePhoneChange}
              required
            />
            {invalidPhone && (
              <P
                fontSizePx={12}
                color={styles.colors.white65}
                lineHeightPx={20}
              >
                {t("modal.requestAdvice.invalidPhone")}
              </P>
            )}
          </PhoneContainer>
          <SpacerContainer />
          <PostalCodeContainer>
            <Label htmlFor="postalCode">
              {t("modal.requestAdvice.postalCodeLabel")}
              <Required>*</Required>
            </Label>
            <Input
              invalid={invalidPostalCode}
              type="text"
              value={postalCode}
              onChange={handlePostalCodeChange}
            />
          </PostalCodeContainer>
        </PhoneRow>
        <Label htmlFor="company">{t("modal.requestAdvice.companyLabel")}</Label>
        <Input
          invalid={false}
          type="text"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
        <Label htmlFor="message">{t("modal.requestAdvice.messageLabel")}</Label>
        <TextArea
          invalid={false}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <OptinRow className={"checkbox"}>
          <Input
            type="checkbox"
            invalid={false}
            id="optin"
            value={optin ? "1" : "0"}
            onChange={(e) => setOptin(e.target.checked)}
          />
          <Label htmlFor="optin">{t("modal.downloadMail.optinLabel")}</Label>
        </OptinRow>
        <OptinRow className={"checkbox"}>
          <Input
            type="checkbox"
            invalid={invalidGdpr}
            id="gdpr"
            value={gdpr ? "1" : "0"}
            onChange={handleGdprChange}
            required
          />
          <Label htmlFor="gdpr">
            <span
              dangerouslySetInnerHTML={{
                __html: t("modal.downloadMail.gdprLabel", {
                  link: `<a href="${t(
                    "email.privacyNoticeURL"
                  )}" rel="noopener" target="_blank" style="color: #fff;">`,
                  endlink: "</a>",
                }),
              }}
            ></span>
            <Required>*</Required>
          </Label>
        </OptinRow>
        <OptinRow>
          {invalidGdpr && (
            <P fontSizePx={12} color={styles.colors.white65} lineHeightPx={20}>
              {t("modal.downloadMail.invalidGdpr")}
            </P>
          )}
        </OptinRow>
      </Form>
    </StandardModal>
  );
};
