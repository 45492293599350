import { RoofFloorTypes } from "@iko-design-center/shared"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useFlatRoof } from "../../../store/flat-roof/hooks"
import { useRoutes } from "../../../store/routes/hooks"
import { useEffect, useState } from "react"
import { useMatrix } from "./matrix.hook"

export function useRoofFloor() {
  const {
    getRemainingCombinations,
    displayDefaultFlatRoofCombination,
  } = useMatrix()
  const [roofFloorOptions, setRoofFloorOptions] = useState<any>([])
  const { Routes } = useRoutes() as any
  const { t } = useTranslation()
  const history = useHistory()
  const {
    configurationState,
    setRoofFloorType: setStoreRoofFloorType,
  } = useFlatRoof()

  const { roofType: stateRoofType } = configurationState
  const roofFloorType = configurationState.roofFloor.type
  const nextButtonDisabled = !configurationState.roofFloor.type

  useEffect(() => {
    // MATRIX FILTERING
    if (!roofFloorOptions.length) {
      const roofFloorOptions = getRemainingCombinations(
        stateRoofType.type,
        stateRoofType.climateClass
      ).map((x: any) => x.roofFloor)
      const uniqueRoofFloorTypesRemaining = roofFloorOptions.filter(
        (item: any, index: any) => roofFloorOptions.indexOf(item) === index
      )

      if (uniqueRoofFloorTypesRemaining.length) {
        setRoofFloorOptions(uniqueRoofFloorTypesRemaining)
      }
    }
  }, [getRemainingCombinations, stateRoofType, roofFloorOptions])

  useEffect(() => {
    displayDefaultFlatRoofCombination()
  }, [displayDefaultFlatRoofCombination, roofFloorType])

  function handleNextButtonClick() {
    history.push(Routes.FLAT_ROOF_3_WATERPROOFING)
  }

  function setRoofFloorType(type: RoofFloorTypes) {
    setStoreRoofFloorType(type)
  }

  return {
    t,
    handleNextButtonClick,
    nextButtonDisabled,
    setRoofFloorType,
    roofFloorOptions,
    roofFloorType,
  }
}
