import { WaterproofingTypes } from "@iko-design-center/shared"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useFlatRoof } from "../../../store/flat-roof/hooks"
import { useRoutes } from "../../../store/routes/hooks"
import { useEffect, useState } from "react"
import { useMatrix } from "./matrix.hook"

export function useWaterproofing() {
  const {
    getRemainingCombinations,
    displayDefaultFlatRoofCombination,
  } = useMatrix()
  const [waterproofingOptions, setWaterproofingOptions] = useState<any>([])
  const { Routes } = useRoutes() as any
  const { t } = useTranslation() as any
  const history = useHistory()
  const {
    configurationState,
    setWaterproofingType: setStoreWaterproofingType,
  } = useFlatRoof()

  const {
    roofType: stateRoofType,
    roofFloor: stateRoofFloor,
  } = configurationState
  const flatRoofWaterproofingType = configurationState.waterproofing.type
  const nextButtonDisabled = !flatRoofWaterproofingType

  useEffect(() => {
    // MATRIX FILTERING
    if (!waterproofingOptions.length) {
      const waterproofingOptions = getRemainingCombinations(
        stateRoofType.type,
        stateRoofType.climateClass,
        stateRoofFloor.type
      ).map((x: any) => x.waterproofing)
      const uniqueWaterproofingRemaining = waterproofingOptions.filter(
        (item: any, index: any) => waterproofingOptions.indexOf(item) === index
      )
      if (uniqueWaterproofingRemaining.length) {
        setWaterproofingOptions(uniqueWaterproofingRemaining)
      }
    }
  }, [
    getRemainingCombinations,
    stateRoofType,
    stateRoofFloor,
    waterproofingOptions,
  ])

  useEffect(() => {
    displayDefaultFlatRoofCombination()
  }, [displayDefaultFlatRoofCombination, flatRoofWaterproofingType])

  function handleNextButtonClick() {
    history.push(Routes.FLAT_ROOF_4_SECURING_METHODS)
  }

  function setWaterproofingType(type: WaterproofingTypes) {
    setStoreWaterproofingType(type)
  }

  return {
    t,
    flatRoofWaterproofingType,
    waterproofingOptions,
    setWaterproofingType,
    handleNextButtonClick,
    nextButtonDisabled,
  }
}
