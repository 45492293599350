import { useEffect } from "react"
import { useApplication } from "../../../store/application/hooks"
import { Models } from "../../viewer/renderer.api"
import { RoofTypeTypes } from "@iko-design-center/shared"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { useFlatRoof } from "../../../store/flat-roof/hooks"
import { useModal } from "../../../store/modal/hooks"
import { renderer } from "../../viewer/Renderer"
import { useRoutes } from "../../../store/routes/hooks"
import { useMatrix } from "./matrix.hook"
import { RoofTypeClimateClasses } from "@iko-design-center/shared"

export function useRoofType() {
  const { currentModel, directLink, setDirectLink } = useApplication()
  const { Routes } = useRoutes() as any
  const { t, i18n } = useTranslation()
  const { displayClimateClassModal } = useModal()
  const { displayDefaultFlatRoofCombination } = useMatrix()
  const history = useHistory()
  const i18nInfo = i18n.language.split("_")
  const country = i18nInfo[1]
  const hideClimateClass = country === "de"

  const {
    configurationState,
    setRoofTypeClimateClass: setRoofTypeClimateClassState,
    setRoofTypeType: setRoofTypeTypeState,
  } = useFlatRoof()

  const {
    type: roofTypeType,
    climateClass: roofTypeClimateClass,
  } = configurationState.roofType

  const nextButtonDisabled = !roofTypeType

  useEffect(() => {
    if (renderer && currentModel === Models.DOLLHOUSE && directLink) {
      setDirectLink(false)
      displayDefaultFlatRoofCombination()
    }
    if (!hideClimateClass) {
      setRoofTypeClimateClass(RoofTypeClimateClasses.CLIMATE_CLASS_3)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    displayDefaultFlatRoofCombination()
  }, [displayDefaultFlatRoofCombination, roofTypeType, roofTypeClimateClass])

  function handleNextButtonClick() {
    history.push(Routes.FLAT_ROOF_2_ROOF_FLOOR)
  }

  function setRoofTypeType(type: RoofTypeTypes) {
    setRoofTypeTypeState(type)
  }

  function setRoofTypeClimateClass(type: RoofTypeClimateClasses) {
    setRoofTypeClimateClassState(type)
  }

  return {
    t,
    roofTypeType,
    roofTypeClimateClass,
    displayClimateClassModal,
    hideClimateClass,
    handleNextButtonClick,
    setRoofTypeType,
    setRoofTypeClimateClass,
    nextButtonDisabled,
  }
}
