import {
    EXTENDED_FLOOR_R_VALUE_RSI,
    EXTENDED_FLOOR_R_VALUE_RSE,
} from "@iko-design-center/shared";
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useFloor } from "../../../../store/floor/hooks";
import { useExtendedThermalCalculation } from "./extendedCalculation.hook";
import { parseMaterial } from "../../../../services/helpers.service";
import { FloorBearingTypes } from "@iko-design-center/shared/lib/constants/constants";

type State = any
type ThermalStateProviderProps = { children: ReactNode }
const ThermalStateContext = createContext<State | undefined>(undefined)

function ThermalStateProvider({ children }: ThermalStateProviderProps) {
    const { configurationState } = useFloor()
    const {
        calculateMaterialThermalPerformance,
        calculatePreliminaryThermalPerformance,
        calculateCorrection,
        calculateTotalsThermalPerformance,
    } = useExtendedThermalCalculation()

    const initialState: any = {
        floorScreed: {
            type: configurationState.floorScreed.type,
            material: configurationState.floorScreed.material,
        },
        floorInsulation: {
            type: configurationState.floorInsulation.type,
            material: configurationState.floorInsulation.material,
        },
        bearingFloor: {
            type: FloorBearingTypes.COMBINATION_FLOOR,
            material: parseMaterial('COMBINATION_FLOOR'),
        },
    }

    const initialPerformance = {
        floorScreed: calculateMaterialThermalPerformance(initialState.floorScreed.material),
        floorInsulation: calculateMaterialThermalPerformance(initialState.floorInsulation.material),
        bearingFloor: calculateMaterialThermalPerformance(initialState.bearingFloor.material),
        UTValue: null as number | null,
        RTValue: null as number | null,
        convectionUValue: null as number | null,
        fixationUValue: null as number | null,
        deltaUValue: null as number | null,
        UCValue: null as number | null,
        RCValue: null as number | null,
    }

    const initialCorrections = null

    const initialConstants = {
        RSI: { label: 'RSI', RValue: EXTENDED_FLOOR_R_VALUE_RSI },
        RSE: { label: 'RSE', RValue: EXTENDED_FLOOR_R_VALUE_RSE },
    }

    const [state, setState] = useState(initialState)
    const [performance, setPerformance] = useState(initialPerformance)
    const [corrections, setCorrections] = useState(initialCorrections)
    const [constants, setConstants] = useState(initialConstants)

    useEffect(() => {
        // Preliminary
        const floorScreedThermalPerformance = calculateMaterialThermalPerformance(state.floorScreed.material)
        const floorInsulationThermalPerformance = calculateMaterialThermalPerformance(state.floorInsulation.material)
        const bearingFloorThermalPerformance = calculateMaterialThermalPerformance(state.bearingFloor.material)

        const [RTValue, UTValue] = calculatePreliminaryThermalPerformance(
            [
                floorScreedThermalPerformance,
                floorInsulationThermalPerformance,
                bearingFloorThermalPerformance
            ],
            initialConstants.RSI.RValue,
            initialConstants.RSE.RValue
        )
        // Corrections
        const convectionUValue = 0
        const fixationUValue = 0
        const deltaUValue = calculateCorrection(convectionUValue, fixationUValue, UTValue)
        // Totals
        const [RCValue, UCValue] = calculateTotalsThermalPerformance(
            UTValue,
            deltaUValue,
            initialConstants.RSI.RValue,
            initialConstants.RSE.RValue
        )

        const newThermalPerformance = {
            ...performance,
            floorScreed: floorScreedThermalPerformance,
            floorInsulation: floorInsulationThermalPerformance,
            bearingFloor: bearingFloorThermalPerformance,
            RTValue,
            UTValue,
            convectionUValue,
            fixationUValue,
            deltaUValue,
            UCValue,
            RCValue
        }

        setPerformance(newThermalPerformance)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, corrections])

    return (
        <ThermalStateContext.Provider value={{
            state, setState,
            performance, setPerformance,
            corrections, setCorrections,
            constants, setConstants
        }}>
            {children}
        </ThermalStateContext.Provider>
    )
}

function useThermalState() {
    const context = useContext(ThermalStateContext)
    if (context === undefined) {
        throw new Error('useThermalState must be used within a ThermalStateProvider')
    }
    return context
}

export { ThermalStateProvider, useThermalState }
