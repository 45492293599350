import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { modalSlice } from "./store";

export function useModal() {
  const dispatch = useAppDispatch();
  const display = useAppSelector((state) => state.modal.display);

  const closeModal = useCallback(
    () => dispatch(modalSlice.actions.closeModal()),
    [dispatch]
  );

  const displayCountryAndLanguageModal = useCallback(
    () => dispatch(modalSlice.actions.displayCountryAndLanguageModal()),
    [dispatch]
  );

  const displaySupportModal = useCallback(
    () => dispatch(modalSlice.actions.displaySupportModal()),
    [dispatch]
  );

  const displaySupportSuccessModal = useCallback(
    () => dispatch(modalSlice.actions.displaySupportSuccessModal()),
    [dispatch]
  );

  const displayClimateClassModal = useCallback(
    () => dispatch(modalSlice.actions.displayClimateClassModal()),
    [dispatch]
  );

  const displayDetailInfoModal = useCallback(
    () => dispatch(modalSlice.actions.displayDetailInfoModal()),
    [dispatch]
  );

  const displayRequestAdviceModal = useCallback(
    () => dispatch(modalSlice.actions.displayRequestAdviceModal()),
    [dispatch]
  );

  const displayRequestAdviceSuccessModal = useCallback(
    () => dispatch(modalSlice.actions.displayRequestAdviceSuccessModal()),
    [dispatch]
  );

  // FLAT ROOF
  const displayFlatRoofThermalCalculationModal = useCallback(
    () => dispatch(modalSlice.actions.displayFlatRoofThermalCalculationModal()),
    [dispatch]
  );
  const displayFlatRoofExtendedThermalCalculationModal = useCallback(
    () =>
      dispatch(modalSlice.actions.displayFlatRoofExtendedThermalCalculationModal()),
    [dispatch]
  );

  const displayFlatRoofDownloadModal = useCallback(
    () => dispatch(modalSlice.actions.displayFlatRoofDownloadModal()),
    [dispatch]
  );

  const displayFlatRoofDownloadMailModal = useCallback(
    () => dispatch(modalSlice.actions.displayFlatRoofDownloadMailModal()),
    [dispatch]
  );

  const displayFlatRoofDownloadSuccessModal = useCallback(
    () => dispatch(modalSlice.actions.displayFlatRoofDownloadSuccessModal()),
    [dispatch]
  );

  // CAVITY WALL
  const displayCavityWallThermalCalculationModal = useCallback(
    () =>
      dispatch(modalSlice.actions.displayCavityWallThermalCalculationModal()),
    [dispatch]
  );
  const displayCavityWallExtendedThermalCalculationModal = useCallback(
    () =>
      dispatch(modalSlice.actions.displayCavityWallExtendedThermalCalculationModal()),
    [dispatch]
  );

  const displayCavityWallDownloadModal = useCallback(
    () => dispatch(modalSlice.actions.displayCavityWallDownloadModal()),
    [dispatch]
  );

  const displayCavityWallDownloadMailModal = useCallback(
    () => dispatch(modalSlice.actions.displayCavityWallDownloadMailModal()),
    [dispatch]
  );

  const displayCavityWallDownloadSuccessModal = useCallback(
    () => dispatch(modalSlice.actions.displayCavityWallDownloadSuccessModal()),
    [dispatch]
  );

  // SLOPED ROOF
  const displaySlopedRoofThermalCalculationModal = useCallback(
    () =>
      dispatch(modalSlice.actions.displaySlopedRoofThermalCalculationModal()),
    [dispatch]
  );

  const displaySlopedRoofDownloadModal = useCallback(
    () => dispatch(modalSlice.actions.displaySlopedRoofDownloadModal()),
    [dispatch]
  );

  const displaySlopedRoofDownloadMailModal = useCallback(
    () => dispatch(modalSlice.actions.displaySlopedRoofDownloadMailModal()),
    [dispatch]
  );

  const displaySlopedRoofDownloadSuccessModal = useCallback(
    () => dispatch(modalSlice.actions.displaySlopedRoofDownloadSuccessModal()),
    [dispatch]
  );

  // VENTILATED WALL
  const displayVentilatedWallThermalCalculationModal = useCallback(
    () =>
      dispatch(modalSlice.actions.displayVentilatedWallThermalCalculationModal()),
    [dispatch]
  );
  const displayVentilatedWallExtendedThermalCalculationModal = useCallback(
    () =>
      dispatch(modalSlice.actions.displayVentilatedWallExtendedThermalCalculationModal()),
    [dispatch]
  );

  const displayVentilatedWallDownloadModal = useCallback(
    () => dispatch(modalSlice.actions.displayVentilatedWallDownloadModal()),
    [dispatch]
  );

  const displayVentilatedWallDownloadMailModal = useCallback(
    () => dispatch(modalSlice.actions.displayVentilatedWallDownloadMailModal()),
    [dispatch]
  );

  const displayVentilatedWallDownloadSuccessModal = useCallback(
    () => dispatch(modalSlice.actions.displayVentilatedWallDownloadSuccessModal()),
    [dispatch]
  );

  // FLOOR
  const displayFloorThermalCalculationModal = useCallback(
    () =>
      dispatch(modalSlice.actions.displayFloorThermalCalculationModal()),
    [dispatch]
  );
  const displayFloorExtendedThermalCalculationModal = useCallback(
    () =>
      dispatch(modalSlice.actions.displayFloorExtendedThermalCalculationModal()),
    [dispatch]
  );

  const displayFloorDownloadModal = useCallback(
    () => dispatch(modalSlice.actions.displayFloorDownloadModal()),
    [dispatch]
  );

  const displayFloorDownloadMailModal = useCallback(
    () => dispatch(modalSlice.actions.displayFloorDownloadMailModal()),
    [dispatch]
  );

  const displayFloorDownloadSuccessModal = useCallback(
    () => dispatch(modalSlice.actions.displayFloorDownloadSuccessModal()),
    [dispatch]
  );

  // SOL
  const displaySolThermalCalculationModal = useCallback(
    () =>
      dispatch(modalSlice.actions.displaySolThermalCalculationModal()),
    [dispatch]
  );

  const displaySolDownloadModal = useCallback(
    () => dispatch(modalSlice.actions.displaySolDownloadModal()),
    [dispatch]
  );

  const displaySolDownloadMailModal = useCallback(
    () => dispatch(modalSlice.actions.displaySolDownloadMailModal()),
    [dispatch]
  );

  const displaySolDownloadSuccessModal = useCallback(
    () => dispatch(modalSlice.actions.displaySolDownloadSuccessModal()),
    [dispatch]
  );

  return {
    display,
    closeModal,
    displaySupportModal,
    displaySupportSuccessModal,
    displayCountryAndLanguageModal,
    displayClimateClassModal,
    displayDetailInfoModal,
    displayRequestAdviceModal,
    displayRequestAdviceSuccessModal,

    // FLAT ROOF
    displayFlatRoofThermalCalculationModal,
    displayFlatRoofExtendedThermalCalculationModal,
    displayFlatRoofDownloadModal,
    displayFlatRoofDownloadMailModal,
    displayFlatRoofDownloadSuccessModal,

    // CAVITY WALL
    displayCavityWallThermalCalculationModal,
    displayCavityWallExtendedThermalCalculationModal,
    displayCavityWallDownloadModal,
    displayCavityWallDownloadMailModal,
    displayCavityWallDownloadSuccessModal,

    // SLOPED ROOF
    displaySlopedRoofThermalCalculationModal,
    displaySlopedRoofDownloadModal,
    displaySlopedRoofDownloadMailModal,
    displaySlopedRoofDownloadSuccessModal,

    // VENTILATED WALL
    displayVentilatedWallThermalCalculationModal,
    displayVentilatedWallExtendedThermalCalculationModal,
    displayVentilatedWallDownloadModal,
    displayVentilatedWallDownloadMailModal,
    displayVentilatedWallDownloadSuccessModal,

    // FLOOR
    displayFloorThermalCalculationModal,
    displayFloorExtendedThermalCalculationModal,
    displayFloorDownloadModal,
    displayFloorDownloadMailModal,
    displayFloorDownloadSuccessModal,

    // SOL
    displaySolThermalCalculationModal,
    displaySolDownloadModal,
    displaySolDownloadMailModal,
    displaySolDownloadSuccessModal,
  };
}
