import { useFlatRoof } from "../../../store/flat-roof/hooks"
import { renderer } from "../../viewer/Renderer"
import { useEffect, useState } from "react"
import { parseMatrixStringValue } from "../../../services/helpers.service"
import { useMatrix } from "./matrix.hook"

export function useSecuringMethodsVaporShield() {
  const { getRemainingCombinations } = useMatrix()
  const [vaporShieldOptions, setVaporShieldOptions] = useState<any>([])
  const { configurationState, setSecuringMethodVaporShield } = useFlatRoof()
  const { vaporShield: stateVaporShield } = configurationState.securingMethods
  const {
    roofType: stateRoofType,
    roofFloor: stateRoofFloor,
    waterproofing: stateWaterproofing,
  } = configurationState

  useEffect(() => {
    if (vaporShieldOptions.length && !stateVaporShield) {
      const configVaporShield = vaporShieldOptions[0]
      if (stateVaporShield !== configVaporShield) {
        setSecuringMethodVaporShield(configVaporShield)
        renderer.displayVaporShieldMaterial(configVaporShield.type)
        renderer.displaySecuringMethod(
          "DAMPSCHERM-BEVESTIGING",
          configVaporShield.method
        )
      }
    }
  }, [stateVaporShield, setSecuringMethodVaporShield, vaporShieldOptions])

  useEffect(() => {
    // MATRIX FILTERING
    if (!vaporShieldOptions.length) {
      const vaporShieldOptions = getRemainingCombinations(
        stateRoofType.type,
        stateRoofType.climateClass,
        stateRoofFloor.type,
        stateWaterproofing.type
      )
        .sort((a: any) => (a.default ? -1 : 1))
        .map((x: any) => x.vaporShield)
      const uniqueVaporShieldOptions = vaporShieldOptions
        .filter((item, index) => vaporShieldOptions.indexOf(item) === index)
        .map((x: any) => parseMatrixStringValue(x))
      setVaporShieldOptions(uniqueVaporShieldOptions)
    }
  }, [
    getRemainingCombinations,
    stateRoofType,
    stateRoofFloor,
    stateWaterproofing,
    vaporShieldOptions,
  ])

  function setVaporShieldType(value: string) {
    const option = parseMatrixStringValue(value)
    setSecuringMethodVaporShield(option)
    if (option) {
      renderer.displayVaporShieldMaterial(option.type)
      renderer.displaySecuringMethod("DAMPSCHERM-BEVESTIGING", option.method)
    }
  }

  return {
    stateVaporShield,
    vaporShieldOptions,
    setVaporShieldType,
  }
}
