"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialCavityWallThermalPerformanceState = exports.CAVITY_WALL_MINIMUM_INSULATION_THICKNESS = exports.CAVITY_WALL_R_VALUE_AIR_CAVITY = exports.EXTENDED_CAVITY_WALL_R_VALUE_RSE = exports.EXTENDED_CAVITY_WALL_R_VALUE_RSI = exports.CAVITY_WALL_R_VALUE_RSE = exports.CAVITY_WALL_R_VALUE_RSI = void 0;
exports.CAVITY_WALL_R_VALUE_RSI = 0.13;
exports.CAVITY_WALL_R_VALUE_RSE = 0.04;
exports.EXTENDED_CAVITY_WALL_R_VALUE_RSI = 0.13;
exports.EXTENDED_CAVITY_WALL_R_VALUE_RSE = 0.04;
exports.CAVITY_WALL_R_VALUE_AIR_CAVITY = 0.45;
exports.CAVITY_WALL_MINIMUM_INSULATION_THICKNESS = 20;
exports.initialCavityWallThermalPerformanceState = {
    UCValue: null,
    RTValue: null,
    wallType: {
        thickness: null,
        lambda: null,
        RValue: null,
    },
    wallInsulation: {
        thickness: null,
        lambda: null,
        RValue: null,
    },
    outerCavityLeaf: {
        thickness: null,
        lambda: null,
        RValue: null,
    },
};
