"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialFlatRoofConfigurationState = void 0;
exports.initialFlatRoofConfigurationState = {
    humanReadableName: null,
    roofType: {
        type: null,
        climateClass: null,
    },
    roofFloor: {
        type: null,
    },
    waterproofing: {
        type: null,
    },
    securingMethods: {
        topLayer: null,
        bottomLayer: null,
        insulation: null,
        vaporShield: null,
        finishing: null,
    },
    roofStructure: {
        topLayerMaterial: null,
        bottomLayerMaterial: null,
        insulationMaterial: null,
        vaporShieldMaterial: null,
        roofFloorMaterial: null,
    },
    files: {
        nl_be: null,
        fr_be: null,
        nl_nl: null,
        fr_fr: null,
        de_de: null,
    },
};
