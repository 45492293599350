import React from "react";
import styled from "styled-components";
import { styles } from "../../styles";
import { Spacer } from "./Spacer";
import { H2, P } from "./Typography";
import { RadioCheckedIcon, RadioUncheckedIcon } from "./Icons";
import { useTranslation } from "react-i18next";

export type RadioOverviewItemProps = {
    title: string;
    optionState: boolean;
    setOptionState?: any;
};

const NameAndTypeContainer = styled.div`
  display: flex;
  
  p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: ${20 / 16}rem;
    margin-top: 0 !important;

    @media screen and (pointer: coarse) {
      font-size: 1.2rem;
    }
  }
`;

const RadioContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    gap: 12px;
    margin-top: 4px;
`;

const RadioElement = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    width: fit-content;
    gap: 8px;
`;

export const RadioOverviewItem = ({ title, optionState, setOptionState }: RadioOverviewItemProps) => {
    const { t } = useTranslation() as any;
    return (
        <div style={{ width: "100%" }}>
            <H2 fontSizePx={12} lineHeightPx={14.06} color={styles.colors.darkBlue50}>
                {title}
            </H2>
            <Spacer heightPx={2} />
            <NameAndTypeContainer>
                <RadioContainer>
                    <RadioElement onClick={() => setOptionState(true)}>
                        <P>{t('values.yes')}</P>
                        {optionState && <RadioCheckedIcon />}
                        {!optionState && <RadioUncheckedIcon />}
                    </RadioElement>
                    <RadioElement onClick={() => setOptionState(false)}>
                        <P>{t('values.no')}</P>
                        {!optionState && <RadioCheckedIcon />}
                        {optionState && <RadioUncheckedIcon />}
                    </RadioElement>
                </RadioContainer>
            </NameAndTypeContainer>
        </div >
    );
};
