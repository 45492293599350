import styled from "styled-components";
import { RSRow, Separator, TableRow, TableSubtitleRow } from "./ExtendedThermalCalculation";
import { getExtendedInsulationTranslations, getExtendedToplayerTranslations, getExtendedVaporShieldTranslations, getRoofFloorTranslations } from "../translations";
import { useThermalState } from "./ThermalStateProvider";
import { useTranslation } from "react-i18next";
import { SyntheticEvent, useMemo } from "react";
import { getEnumValue, parseMaterial } from "../../../../services/helpers.service";
import { ExtendedInsulationTypes, ExtendedRoofFloorTypes, ExtendedTopLayerTypes, ExtendedVaporShieldTypes } from "@iko-design-center/shared/lib/constants/constants";
import { useExtendedThermalCalculation } from "./extendedCalculation.hook";

const TableBody = styled.div`
`;

export const PreliminaryTableBody = () => {
    const { t } = useTranslation() as any;
    const { state, setState, performance } = useThermalState();
    const { formatCalc } = useExtendedThermalCalculation();

    const roofVaporShieldOptions = useMemo(() => {
        return [
            { value: state.roofVaporShield.material.id, label: getExtendedVaporShieldTranslations(t, state.roofVaporShield.type) },
            { value: "EXT_PE_FOIL_VAPORSHIELD", label: getExtendedVaporShieldTranslations(t, ExtendedVaporShieldTypes.EXT_PE_FOIL_VAPORSHIELD) },
            { value: "EXT_BITUMINOUS_VAPORSHIELD", label: getExtendedVaporShieldTranslations(t, ExtendedVaporShieldTypes.EXT_BITUMINOUS_VAPORSHIELD) },
        ].filter((x, index, array) => index === array.findIndex((t) => t.value === x.value))
        // .sort((a, b) => a.label.localeCompare(b.label));
    }, [state.roofVaporShield, t]);

    const roofInsulationOneOptions = useMemo(() => {
        return [
            { value: state.roofInsulationOne.material.id, label: getExtendedInsulationTranslations(t, state.roofInsulationOne.type) },
            { value: "EXT_ENERTHERM_ALU_ALU_F4", label: getExtendedInsulationTranslations(t, ExtendedInsulationTypes.EXT_ENERTHERM_ALU_ALU_F4) },
            { value: "EXT_ENERTHERM_ATELIA", label: getExtendedInsulationTranslations(t, ExtendedInsulationTypes.EXT_ENERTHERM_ATELIA) },
            { value: "EXT_ENERTHERM_BM", label: getExtendedInsulationTranslations(t, ExtendedInsulationTypes.EXT_ENERTHERM_BM) },
            { value: "EXT_ENERTHERM_MG", label: getExtendedInsulationTranslations(t, ExtendedInsulationTypes.EXT_ENERTHERM_MG) },
        ].filter((x, index, array) => index === array.findIndex((t) => t.value === x.value))
        // .sort((a, b) => a.label.localeCompare(b.label));
    }, [state.roofInsulationOne, t]);

    const roofInsulationTwoOptions = useMemo(() => {
        return [
            { value: state.roofInsulationTwo.material.id, label: getExtendedInsulationTranslations(t, state.roofInsulationTwo.type) },
            { value: "EXT_ENERTHERM_ALU_ALU_F4", label: getExtendedInsulationTranslations(t, ExtendedInsulationTypes.EXT_ENERTHERM_ALU_ALU_F4) },
            { value: "EXT_ENERTHERM_ATELIA", label: getExtendedInsulationTranslations(t, ExtendedInsulationTypes.EXT_ENERTHERM_ATELIA) },
            { value: "EXT_ENERTHERM_BM", label: getExtendedInsulationTranslations(t, ExtendedInsulationTypes.EXT_ENERTHERM_BM) },
            { value: "EXT_ENERTHERM_MG", label: getExtendedInsulationTranslations(t, ExtendedInsulationTypes.EXT_ENERTHERM_MG) },
            { value: "EXT_ENERTHERM_NONE", label: getExtendedInsulationTranslations(t, ExtendedInsulationTypes.EXT_ENERTHERM_NONE) },
        ].filter((x, index, array) => index === array.findIndex((t) => t.value === x.value))
        // .sort((a, b) => a.label.localeCompare(b.label));
    }, [state.roofInsulationTwo, t]);

    const roofToplayerOptions = useMemo(() => {
        return [
            { value: state.roofToplayer.material.id, label: getExtendedToplayerTranslations(t, state.roofToplayer.type) },
            { value: "EXT_BITUMINOUS_MEMBRANE", label: getExtendedToplayerTranslations(t, ExtendedTopLayerTypes.EXT_BITUMINOUS_MEMBRANE) },
            { value: "EXT_PVC", label: getExtendedToplayerTranslations(t, ExtendedTopLayerTypes.EXT_PVC) },
            { value: "EXT_EPDM", label: getExtendedToplayerTranslations(t, ExtendedTopLayerTypes.EXT_EPDM) },
        ].filter((x, index, array) => index === array.findIndex((t) => t.value === x.value))
        // .sort((a, b) => a.label.localeCompare(b.label));
    }, [state.roofToplayer, t]);

    const handleEditableThicknessChange = (event: SyntheticEvent<HTMLInputElement>) => {
        const input = event.target as HTMLInputElement;
        const type = input.dataset.type!;
        let thickness = isNaN(parseInt(input.value)) ? 0 : parseInt(input.value);

        let validThickness = true;
        if (!thickness) validThickness = false;

        if (type) {
            setState({
                ...state,
                [type]: {
                    ...state[type],
                    valid: validThickness,
                    material: { ...state[type].material, thickness },
                },
            });
        }
    }

    const handleEditableOptionChange = (event: SyntheticEvent<HTMLSelectElement>, EnumType: any) => {
        const input = event.target as HTMLInputElement
        const type = input.dataset.type!
        const material = parseMaterial(input.value)

        if (material) {
            setState({
                ...state,
                [type]: { ...state[type], type: getEnumValue(input.value, EnumType), material },
            });
        }
    }

    return (
        <TableBody>
            <RSRow type={"RSI"} />

            <TableSubtitleRow>{t("extendedThermalCalculation.preliminary.subtitle")}</TableSubtitleRow>
            <Separator />

            {/* Roof floor */}
            <TableRow
                type="roofFloor"
                title={t("flatRoof.overview.roofFloor").toUpperCase()}
                text={getRoofFloorTranslations(t, state.roofFloor.type)}
                thickness={performance.roofFloor.thickness!}
                lambdaValue={formatCalc(performance.roofFloor.lambda!)}
                insulationValue={formatCalc(performance.roofFloor.RValue!)}

                editableThickness={true}
                editableThicknessValue={performance.roofFloor.thickness!}
                onEditableThicknessChange={handleEditableThicknessChange}
                editableThicknessValid={performance.roofFloor.valid}

                options={state.roofFloor.options}
                onOptionChange={(event) => handleEditableOptionChange(event, ExtendedRoofFloorTypes)}
            />

            {/* Roof vaporshield */}
            <Separator margin="0" />
            <TableRow
                classes="-even-row"
                type="roofVaporShield"
                title={t("flatRoof.overview.vaporShield").toUpperCase()}
                text={getExtendedVaporShieldTranslations(t, state.roofVaporShield.type)}
                thickness={performance.roofVaporShield.thickness!}
                lambdaValue={formatCalc(performance.roofVaporShield.lambda!)}
                insulationValue={formatCalc(performance.roofVaporShield.RValue!)}

                editableThickness={true}
                editableThicknessValue={performance.roofVaporShield.thickness!}
                onEditableThicknessChange={handleEditableThicknessChange}
                editableThicknessValid={performance.roofVaporShield.valid}

                options={roofVaporShieldOptions}
                onOptionChange={(event) => handleEditableOptionChange(event, ExtendedVaporShieldTypes)}
            />

            {/* Roof insulation one */}
            <Separator margin="0" />
            <TableRow
                type="roofInsulationOne"
                title={t("flatRoof.overview.insulation").toUpperCase()}
                text={getExtendedInsulationTranslations(t, state.roofInsulationOne.type)}
                thickness={performance.roofInsulationOne.thickness!}
                lambdaValue={formatCalc(performance.roofInsulationOne.lambda!)}
                insulationValue={formatCalc(performance.roofInsulationOne.RValue!)}

                editableThickness={true}
                editableThicknessValue={performance.roofInsulationOne.thickness!}
                onEditableThicknessChange={handleEditableThicknessChange}
                editableThicknessValid={performance.roofInsulationOne.valid}

                options={roofInsulationOneOptions}
                onOptionChange={(event) => handleEditableOptionChange(event, ExtendedInsulationTypes)}
            />

            {/* Roof insulation two */}
            <Separator margin="0" />
            <TableRow
                type="roofInsulationTwo"
                title={t("flatRoof.overview.insulation").toUpperCase()}
                text={getExtendedInsulationTranslations(t, state.roofInsulationTwo.type)}
                thickness={performance.roofInsulationTwo.thickness!}
                lambdaValue={formatCalc(performance.roofInsulationTwo.lambda!)}
                insulationValue={formatCalc(performance.roofInsulationTwo.RValue!)}

                editableThickness={true}
                editableThicknessValue={performance.roofInsulationTwo.thickness!}
                onEditableThicknessChange={handleEditableThicknessChange}
                editableThicknessValid={performance.roofInsulationTwo.valid}

                options={roofInsulationTwoOptions}
                onOptionChange={(event) => handleEditableOptionChange(event, ExtendedInsulationTypes)}
            />

            {/* Roof toplayer */}
            <Separator margin="0" />
            <TableRow
                type="roofToplayer"
                title={t("flatRoof.overview.topLayer").toUpperCase()}
                text={getExtendedToplayerTranslations(t, state.roofToplayer.type)}
                thickness={undefined}
                lambdaValue={undefined}
                insulationValue={formatCalc(performance.roofToplayer.RValue!)}

                options={roofToplayerOptions}
                onOptionChange={(event) => handleEditableOptionChange(event, ExtendedTopLayerTypes)}
            />

            <Separator margin="0" />

            <RSRow type={"RSE"} />
        </TableBody>
    )
}

