import { useFlatRoof } from "../flat-roof/hooks"
import {
  BottomLayerTypes,
  InsulationTypes,
  RoofFloorTypes,
  RoofTypeClimateClasses,
  RoofTypeTypes,
  TopLayerTypes,
  VaporShieldTypes,
  WaterproofingTypes,
} from "@iko-design-center/shared"
import { useHistory } from "react-router"
import { useRoutes } from "../routes/hooks"
import {
  parseMaterial,
  parseMatrixStringValue,
} from "../../services/helpers.service"
import { Models } from "../../components/viewer/renderer.api"
import { useApplication } from "../application/hooks"

export function useQueryParams() {
  const { setCurrentModel } = useApplication()
  const { Routes } = useRoutes()
  const history = useHistory()

  const {
    setRoofTypeClimateClass,
    setRoofTypeType,
    setRoofFloorType,
    setRoofFloorMaterial,
    setWaterproofingType,
    setSecuringMethodTopLayer,
    setTopLayerMaterial,
    setSecuringMethodBottomLayer,
    setBottomLayerMaterial,
    setSecuringMethodInsulation,
    setInsulationMaterial,
    setSecuringMethodVaporShield,
    setVaporShieldMaterial,
    setSecuringMethodFinishing,
  } = useFlatRoof()

  const redirectBasedOnQueryParams = () => {
    const url = new URL(window.location.href)
    const query = {
      roofType: url.searchParams.get("roof-type"),
      climateClass: url.searchParams.get("climate-class"),
      roofFloor: url.searchParams.get("roof-floor"),
      waterproofing: url.searchParams.get("waterproofing"),
      topLayer: url.searchParams.get("toplayer"),
      bottomLayer: url.searchParams.get("bottomlayer"),
      insulation: url.searchParams.get("insulation"),
      vaporShield: url.searchParams.get("vaporshield"),
      finishing: url.searchParams.get("finishing"),
      detail: url.searchParams.get("detail"),
    }

    // Roof Type
    const validRoofType = checkValidQueriedValueOfType(
      query.roofType,
      RoofTypeTypes
    )
    if (validRoofType) setRoofTypeType(validRoofType)

    // Climate Class
    const validClimateClass = checkValidQueriedValueOfType(
      query.climateClass,
      RoofTypeClimateClasses
    )
    if (validClimateClass) setRoofTypeClimateClass(validClimateClass)

    // Roof Floor
    const validRoofFloor = checkValidQueriedValueOfType(
      query.roofFloor,
      RoofFloorTypes
    )
    if (validRoofFloor) setRoofFloorType(validRoofFloor)
    // console.log(validRoofFloor)

    let roofFloorMaterial = null
    switch (validRoofFloor) {
      case RoofFloorTypes.WOOD:
        roofFloorMaterial = parseMaterial("ROOF_FLOOR_WOOD")
        break
      case RoofFloorTypes.CONCRETE:
        roofFloorMaterial = parseMaterial("ROOF_FLOOR_CONCRETE")
        break
      case RoofFloorTypes.STEEL_DECK:
        roofFloorMaterial = parseMaterial("ROOF_FLOOR_STEEL_DECK")
        break
    }
    if (roofFloorMaterial) setRoofFloorMaterial(roofFloorMaterial)

    // Waterproofing
    const validWaterproofing = checkValidQueriedValueOfType(
      query.waterproofing,
      WaterproofingTypes
    )
    if (validWaterproofing) setWaterproofingType(validWaterproofing)

    // Top layer
    const validTopLayer = checkValidQueriedValueOfSecuringMethod(
      query.topLayer,
      TopLayerTypes
    )
    if (validTopLayer) {
      setSecuringMethodTopLayer(validTopLayer)
      const topLayerMaterial = parseMaterial(validTopLayer.type)
      if (topLayerMaterial) setTopLayerMaterial(topLayerMaterial)
    }

    // Bottom layer
    const validBottomLayer = checkValidQueriedValueOfSecuringMethod(
      query.bottomLayer,
      BottomLayerTypes
    )
    if (validBottomLayer) {
      setSecuringMethodBottomLayer(validBottomLayer)
      const bottomLayerMaterial = parseMaterial(validBottomLayer.type)
      if (bottomLayerMaterial) setBottomLayerMaterial(bottomLayerMaterial)
    }

    // Insulation
    const validInsulation = checkValidQueriedValueOfSecuringMethod(
      query.insulation,
      InsulationTypes
    )
    if (validInsulation) {
      setSecuringMethodInsulation(validInsulation)
      const insulationMaterial = parseMaterial(validInsulation.type)
      if (insulationMaterial) setInsulationMaterial(insulationMaterial)
    }

    // Vapor Shield
    const validVaporShield = checkValidQueriedValueOfSecuringMethod(
      query.vaporShield,
      VaporShieldTypes
    )
    if (validVaporShield) {
      setSecuringMethodVaporShield(validVaporShield)
      const vaporShieldMaterial = parseMaterial(validVaporShield.type)
      if (vaporShieldMaterial) setVaporShieldMaterial(vaporShieldMaterial)
    }

    // Finishing
    const validFinishing = query.finishing
    if (validFinishing) setSecuringMethodFinishing(validFinishing)

    // Model
    const validModel = checkValidQueriedValueOfType(query.detail, Models)
    if (validModel) setCurrentModel(validModel)

    // Routing
    let route = Routes.FLAT_ROOF_1_ROOF_TYPE
    if (
      validRoofType &&
      // validClimateClass &&
      validRoofFloor &&
      validWaterproofing &&
      // validFinishing &&
      validTopLayer &&
      // validBottomLayer &&
      validInsulation &&
      validVaporShield
    ) {
      route = Routes.FLAT_ROOF_5_OVERVIEW
    } else if (
      validRoofType &&
      validClimateClass &&
      validRoofFloor &&
      validWaterproofing
    ) {
      route = Routes.FLAT_ROOF_3_WATERPROOFING
    } else if (validRoofType && validClimateClass && validRoofFloor) {
      route = Routes.FLAT_ROOF_2_ROOF_FLOOR
    }

    history.push(route as any)
  }

  const checkValidQueriedValueOfType = (
    queryValue: string | null,
    Type: any
  ) => {
    if (!queryValue) return
    const key = queryValue as keyof typeof Type
    return Type[key]
  }

  const checkValidQueriedValueOfSecuringMethod = (
    queryValue: string | null,
    Type: any
  ) => {
    if (!queryValue) return
    return parseMatrixStringValue(queryValue)
  }

  return { redirectBasedOnQueryParams }
}
